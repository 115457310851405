import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ToastMessage from "./components/toastMessage/ToastMessage";

function MainView() {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [isGradeNavVisible, setIsGradeNavVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/grade")) {
      setIsGradeNavVisible(false);
    }
    if (!location.pathname.includes("/subject")) {
      setIsSubMenuVisible(false);
    }
    if (location.pathname.includes("/search")) {
      setIsSearchVisible(true);
    } else {
        setIsSearchVisible(false);
    }
  }, [location]);

  return (
    <div className="main-container">      
      <ToastMessage />
      <NavBar
        isSearchVisible={isSearchVisible}
        isSubMenuVisible={isSubMenuVisible}
        setIsSubMenuVisible={setIsSubMenuVisible}
        isGradeNavVisible={setIsGradeNavVisible}
      />
      <div
        className={`page-view-container ${
          isSubMenuVisible ? "with-sub-menu" : ""
        }`}
      >
        <Outlet isSubMenuVisible={isSubMenuVisible} />
      </div>
      <Footer />
    </div>
  );
}

export default MainView;
