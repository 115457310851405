import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import StarRatings from "react-star-ratings";
import GameListCorousal from "../../components/gameListCorousal/GameListCorousal";
import MainLoader from "../../components/mainLoader/MainLoader";
import {
  CreateFavoriteGame,
  CreateWatchlist,
  RemoveFavoriteGame,
  RemoveWatchlist,
  AddReview,
  GameDetails,
  GetRecommendedGames,
  GetReview,
  SetDefaultGameReviews,
  PlayGame,
  SetDefaultPlayGameData,
  ShowToastMessage,
  ShowLoginModal,
  RemoveReview,
  UserTokenCheck,
} from "../../redux/actions";
import {
  encodedId,
  homepageGamesModify,
  getDateFullMonthFormat,
} from "../../utils/Common";
import { getEnvUrl, getGamePlayUrl } from "../../utils/Api";
import user from "../../assets/images/user.png";
import play from "../../assets/icons/play.png";
import playlistadd from "../../assets/icons/playlistadd.png";
import favouriteadd from "../../assets/icons/favourite.png";
// import share from "../../assets/icons/share.png";
import gameclose from "../../assets/icons/gameclose.png";
import gamefullscreen from "../../assets/icons/gamefullscreen.png";
import gamereplay from "../../assets/icons/gamereplay.png";
import deleteicon from "../../assets/icons/deleteicon.png";
import "./GameDetailPage.css";
//import { getEnvUrl } from "../../utils/Api";

const ratingStar = (rating) => {
  return {
    rating: rating || 0,
    starDimension: "20px",
    starSpacing: "1px",
    starRatedColor: "#F79420",
    starEmptyColor: "black",
  };
};
let getBannerWidth=()=>{
  const { innerWidth: width, innerHeight: height } = window;
  if(width>2000){
    width=1400;;
  }
  return width;
}
function GameDetailPage() {
  const localStorageToken = localStorage.getItem("token")?.split(" ")[1];
  let { gameId } = useParams();
  const dispatch = useDispatch();
  const gameViewRef = useRef(null);
  const gameIframeRef = useRef();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [showAddReview, setShowAddReview] = useState(false);
  const [reviewCount, setReviewCount] = useState(1);
  const [reviewError, setReviewError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isgameaccess, setIsGameAccess] = useState(false);
  const { getReviewData, getReviewMsg, addReviewData } = useSelector(
    (state) => state.gameStore.gameReviewData
  );
  const { gameDetailsData, recommendedGamesData, playGameData } = useSelector(
    (state) => state.gameStore.gameData
  );
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  const checkWishlistData = useSelector(
    (state) => state.gameStore.gameWatchlistData.checkWishlistData
  );
  const mySubscriptionDataId = useSelector(
    (state) => state.subscriptionStore.mySubscriptionData.id
  );
  const handlePlaylistClick = (id) => {
    if (checkWishlistData[id]?.watchlist) {
      dispatch(RemoveWatchlist({ game_id: Number.parseInt(id) }));
    } else {
      dispatch(CreateWatchlist({ game_id: Number.parseInt(id) }));
    }
  };
  const handleFavouriteClick = (id) => {
    if (checkWishlistData[id]?.favourite) {
      dispatch(RemoveFavoriteGame({ game_id: Number.parseInt(id) }));
    } else {
      dispatch(CreateFavoriteGame({ game_id: Number.parseInt(id) }));
    }
  };
  useEffect(() => {
    const handleKeyClick = (e) => {
      e.stopPropagation();
      if (playGameData.key && !isLoading) {
        const iframeBody = gameIframeRef?.current?.contentWindow;
        iframeBody?.focus();
        // iframeBody?.dispatchEvent(
        //   new KeyboardEvent('keydown', { key: e.key })
        // );
      }
    };
    document.addEventListener("keydown", handleKeyClick, true);
    return () => {
      document.removeEventListener("keydown", handleKeyClick, true);
    };
  });
  useEffect(() => {
    dispatch(GetReview({ game_id: gameId, hit: reviewCount }));
  }, [reviewCount]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setReviewCount(1);
    setIsFullScreen(false);
    const id = encodedId(gameId);
    const token = localStorageToken ? localStorageToken : "";
    dispatch(SetDefaultGameReviews());
    dispatch(GameDetails({ id, token }));
    dispatch(SetDefaultPlayGameData());
  }, [gameId, userProfileMobile]);
  useEffect(() => {
    if (gameDetailsData?.id && gameDetailsData?.id === gameId) {
      dispatch(
        GetRecommendedGames({
          level_id: encodedId(gameDetailsData.gamelevel.id),
        })
      );
      // setIsLoading(false);
    } else {
      // setIsLoading(true);
    }
  }, [gameDetailsData]);
  useEffect(() => {
    if (addReviewData?.message === "Review inserted") {
      document.body.scrollTop = 200;
      document.documentElement.scrollTop = 200;
      setShowAddReview(false);
    }
  }, [addReviewData]);
  useEffect(() => {
    if (playGameData.key) {
      document.body.style.overflow = "auto";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setIsLoading(false);
    }
  }, [playGameData]);
  const handleChangeRating = (newRating, name) => {
    setRating(newRating);
    setReviewError(false);
  };
  const handleAddReview = () => {
    const reviewData = review.trim();
    if (reviewData.length > 0 && rating) {
      dispatch(
        AddReview({
          game_id: Number.parseInt(gameId),
          review: review,
          rating: rating,
        })
      );
      setRating(0);
      setReview("");
      dispatch(ShowToastMessage("Review successfully added"));
    } else {
      setReviewError(true);
    }
  };
  const handleCancelReview = () => {
    setRating(0);
    setReview("");
    setShowAddReview(false);
  };
  const handlePlayGame = (id) => {
    // if (!userProfileMobile) {
    //   dispatch(ShowLoginModal());
    // }else if (!mySubscriptionDataId) {
    //   dispatch(ShowToastMessage("Please subscribe to play game"));
    // }else if (gameDetailsData.userAccess === "NO") {
    //   setIsGameAccess(true);
    // } else {
    //   setIsLoading(true);
    //   dispatch(PlayGame({ game_id: encodedId(id) }));
    // }

    if (!userProfileMobile) {
      dispatch(ShowLoginModal());
    } else {
      setIsLoading(true);
      dispatch(PlayGame({ game_id: encodedId(id) }));
    }
    
  };
  const handleGameClose = () => {
    dispatch(SetDefaultPlayGameData());
    if (isFullScreen) {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };
  const handleFullScreen = () => {
    if (!isFullScreen) {
      gameViewRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };
  const handleReloadGame = () => {
    handlePlayGame(gameId);
  };
  const handleDeleteReview = (reviewId) => {
    dispatch(
      RemoveReview({
        review_id: reviewId,
        game_id: gameId,
      })
    );
    dispatch(ShowToastMessage("Review successfully deleted"));
  };
  const loggedUserId = localStorage.getItem("userid")
    ? localStorage.getItem("userid")
    : "";
  const handleGamePayment = () => {
    let form = document.createElement("form");
    const gameToken = localStorageToken ? localStorageToken : "";
    form.setAttribute("method", "post");
    form.setAttribute("action", `${getEnvUrl()}payment/gameOnlinePayment`);
    let game_id = document.createElement("input");
    game_id.setAttribute("name", "game_id");
    game_id.setAttribute("value", gameDetailsData.id);

    let token = document.createElement("input");
    token.setAttribute("name", "token");
    token.setAttribute("value", gameToken);
    form.appendChild(game_id);
    form.appendChild(token);
    document.getElementsByTagName("body")[0].appendChild(form).submit();
  };

  return (
    <>
      {isgameaccess && (
        <div className="game-details-notaccessable-popup">
          <div className="game-notaccessable-popup-view">
            <div className="game-access-message">
              You don't have access to this game.
              <div>Pay ₹{gameDetailsData.game_price} to play the game.</div>
            </div>
            <div className="game-access-button">
              <button
                onClick={() => setIsGameAccess(false)}
                className="game-access-no-button"
              >
                Cancel
              </button>
              <button
                onClick={() => handleGamePayment()}
                className="game-access-yes-button"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      )}
      {playGameData.key && !isLoading ? (
        <div
          className={`game-play-container ${
            isFullScreen ? "game-full-screen" : ""
          }`}
          ref={gameViewRef}
        >
          <iframe
            src={`${getGamePlayUrl()}${playGameData.key}`}
            ref={gameIframeRef}
            referrerPolicy="origin"
          ></iframe>
          <div
            className={
              isFullScreen
                ? "game-play-actions-fullscreen"
                : "game-play-actions"
            }
          >
            <button
              className="game-play-button play-button-close"
              onClick={() => handleGameClose()}
            >
              <img src={gameclose} alt="close" />
            </button>
            <button
              className="game-play-button play-button-fullscreen"
              onClick={() => handleFullScreen()}
            >
              <img src={gamefullscreen} alt="fullscreen" />
            </button>
            <button
              className="game-play-button play-button-replay"
              onClick={() => handleReloadGame()}
            >
              <img src={gamereplay} alt="replay" />
            </button>
          </div>
        </div>
      ) : (
        <div className="game-detail-page-container">
          <MainLoader key={"gameDetailPage"} isLoading={isLoading} />
          <div className="game-detail-page-breadcrump">
            <Link to={`/`}>Home</Link> |{" "}
            {gameDetailsData?.gamelevel?.gamesubject?.name} |{" "}
            <Link to={`/games/subject-${gameDetailsData?.gamelevel?.id}`}>
              {gameDetailsData?.gamelevel?.name} 
            </Link>

            {' | '}
            <span className="game-detail-name">{gameDetailsData?.name}</span>
          </div>
          <div className="game-detail-page-banner">
            <img
              src={`${getEnvUrl()}game/img?type=b&id=${encodedId(
                gameId
              )}&width=${getBannerWidth()}`}
              alt="game-detail"
              className={imageLoaded ? "" : "hide-image"}
              onLoad={() => setImageLoaded(true)}
              onError={() => console.log("error loading image")}
            />
            <div className={imageLoaded ? "hide-image" : "loading-image"}></div>
            <div className="game-detail-banner-actions-container">
              <div className="game-detail-banner-title-container">
                <div className="game-detail-banner-title">
                  {gameDetailsData?.name}
                </div>
                <div className="game-detail-banner-source">
                  {gameDetailsData?.gamelevel?.name} .{" "}
                  {gameDetailsData?.gamelevel?.gamesubject?.name}
                </div>
              </div>
              <div className="game-detail-banner-actions">
                  {gameDetailsData.userAccess === "NO" ? (
                    <button
                      className="game-detail-banner-play-actions game-action"
                      onClick={() => handlePlayGame(gameDetailsData.id)}
                    >
                      Pay Now ₹{gameDetailsData.game_price}
                    </button>
                  ) : (
                    <button
                      className="game-detail-banner-play-actions game-action"
                      onClick={() => handlePlayGame(gameDetailsData.id)}
                    >
                      <img src={play} alt="play" /> Play Now
                    </button>
                  )} 

                  {userProfileMobile && (
                    <>
                      <button
                        className="game-detail-banner-playlist-actions game-action"
                        onClick={() => handlePlaylistClick(gameDetailsData.id)}
                      >
                        <img src={playlistadd} alt="playlist add" />{" "}
                        {checkWishlistData[gameDetailsData.id]?.watchlist ? "Remove" : "Add"} Playlist
                      </button>
                      <button
                        className="game-detail-banner-playlist-actions game-action"
                        onClick={() => handleFavouriteClick(gameDetailsData.id)}
                      >
                        <img src={favouriteadd} alt="favourites add" />{" "}
                        {checkWishlistData[gameDetailsData.id]?.favourite ? "Remove" : "Add"} Favourites
                      </button>
                    </>
                  )}
                </div>
            </div>
          </div>
          <div className="game-detail-banner-descrption">Description</div>
          <div className="game-detail-banner-desc">
            {gameDetailsData?.description}
          </div>
          
          <div className="game-detail-reviews">
            <div className="game-detail-reviews-title-container">
              <div className="game-detail-reviews-title">Customer Reviews</div>
              {/* <div className="game-detail-reviews-count">
              52 reviews .{" "}
              <span className="game-detail-reviews-more">See more</span>
            </div> */}
            </div>
            
            <div className="game-detail-reviews-container">
              {getReviewData.length ? (
                <InfiniteScroll
                  dataLength={getReviewData?.length || 0}
                  next={() => setReviewCount(reviewCount + 1)}
                  hasMore={getReviewMsg !== "Data not found"}
                  loader={
                    <MainLoader
                      key={"gameDetailPageSuggestions"}
                      isLoading={true}
                      isFullPage={false}
                    />
                  }
                >
                  
                  {getReviewData?.map((review, idx) => (
                    <div
                      key={review.id + idx}
                      className="game-detail-user-review"
                    >
                      <div className="game-detail-review-main">
                        <div className="game-detail-review-user-main">
                          <img src={user} alt="user pic" />
                          <div className="game-detail-review-user-name">
                            {review.useraccount.display_name ||
                              review.useraccount.mobile_number}
                            <div className="game-detail-review-user-date">
                              {getDateFullMonthFormat(review.createdat)}
                            </div>
                          </div>
                        </div>
                        <div className="game-detail-review-user-rating">
                          <div className="game-detail-review-star">
                            <StarRatings {...ratingStar(review.rating)} />
                          </div>
                          {review.rating}
                        </div>
                      </div>
                      <div className="game-detail-review-comment">
                        {review.review}
                        {loggedUserId == review?.useraccount?.id && (
                          <button
                            className="game-detail-review-delete-icon"
                            onClick={() => handleDeleteReview(review.id)}
                          >
                            <img src={deleteicon} alt="delete review" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                <div className="reviews-no-data">No Reviews Available</div>
              )}
            </div>
          </div>
           {gameDetailsData.userAccess !== "NO" && (
                    <button
                      className="game-review-button"
                      onClick={() => setShowAddReview(!showAddReview)}
                      style={{ marginBottom: '10px' }}
                    >
                      Game Review
                    </button>
                  )}
          <br/>
          {userProfileMobile && mySubscriptionDataId && (
            <div className="game-detail-review-container">
              {showAddReview ? (
                <div className="game-review-input-container">
                  <textarea
                    rows="4"
                    className="game-review-input"
                    onChange={(e) => {
                      setReviewError(false);
                      setReview(e.target.value);
                    }}
                    value={review}
                    placeholder="Enter your review here..."
                  />
                  <div className="rating-star-view">
                    Rating:{" "}
                    <StarRatings
                      rating={rating}
                      starDimension="20px"
                      starSpacing="1px"
                      starRatedColor="#F79420"
                      starEmptyColor="white"
                      changeRating={handleChangeRating}
                      name="reviewRating"
                    />
                  </div>
                  {reviewError && (
                    <div className="review-error">
                      Review or Rating is missing, Please fill all data.
                    </div>
                  )}
                  <button
                      className="game-review-add-button"
                      onClick={() => handleAddReview()}
                      disabled={gameDetailsData.userAccess === "NO"}
                    >
                      Add Review
                    </button>
                  <button
                    className="game-review-add-button"
                    onClick={() => handleCancelReview()}
                  >
                    Cancel
                  </button>
                </div>
              ) :null
            }
            </div>
          )}
          <GameListCorousal
            title="Recommends"
            viewtype="v"
            imagesData={homepageGamesModify(recommendedGamesData)}
          />
        </div>
      )}
    </>
  );
}

export default GameDetailPage;