import axiosInstance from "../../utils/Api";

export const SubscriptionPlanListApi = async (data) =>
  await axiosInstance
    .get("subscription/list", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const SubscriptionPlanIconApi = async (data) =>
    await axiosInstance
    .get(`admin/subscription/subscriptionicon?id=${data.id}`, {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const MySubscriptionApi = async () =>
  await axiosInstance
    .get("user/mysubscription", {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    })
    .then((data) => { 
      return data.data;
    })
    .catch((error) => {});

export const MySubscriptionUpgradeApi = async () => 
  await axiosInstance
    .get("subscription/getUpgradePlans", {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    })
    .then((data) => { 
      return data.data;
    })
    .catch((error) => {});

