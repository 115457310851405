import { getEnvUrl } from "./Api";

export const shortMonths = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const encodedId = (id) => {
  return btoa(btoa(Number.parseInt(id)));
};

export const homepageGamesModify = (gamesList,view=null) => {
 if(view==null || view=='v'){
  return gamesList?.map((data, idx) => {
    return {
      ...data,
      gamethumbnails: `${getEnvUrl()}game/img?type=v&id=${encodedId(data.id)}`,
      isLoading: true,
    };
  });
 }else{
  return gamesList?.map((data, idx) => {
    return {
      ...data,
      gamethumbnails: `${getEnvUrl()}game/img?type=h&id=${encodedId(data.id)}`,
      isLoading: true,
    };
  });
 }
  
};

export const gameBannersModify = (gameBanners) => {
  return gameBanners?.map((banner) => {
    return {
      ...banner,
      src: `${getEnvUrl()}game/getBannerimg?type=WEB&id=${encodedId(
        banner.banner_id
      )}`,
      isLoading: true,
    };
  });
};

export const validatePhoneNumber = (num) => {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(num);
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(91|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+91 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

export const daysDifference = (date) => {
  if (!date) {
    return "";
  }
  var date1 = new Date();
  var date2 = new Date(date);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Number.parseInt(Difference_In_Days);
};

export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const validateUserName = (userName) => {
  const userNameValied = userName.trim();
  if (userNameValied.length>0 && /^[A-Za-z. ]{1,29}$/.test(userNameValied)) {
    return true;
  }
  return false;
};

export const dateFormat = (receivedDate) => {
  let objectDate = new Date(receivedDate);
  let day = objectDate.getDate();
  let formatDay = day < 10 ? `0${day}` : day;
  let month = objectDate.getMonth()+1;
  let formatMonth = month < 10 ? `0${month}` : month;
  let year = objectDate.getFullYear();
  return `${formatDay}-${formatMonth}-${year}`;
};


export const getDateFullMonthFormat = (date) => {
  let objectDate = new Date(date);
  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();
  return `${day} ${shortMonths[month]} ${year}`;
};
