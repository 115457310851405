import { USER_LOGIN_DATA, USER_TOKEN_CHECK_DATA, USER_PROFILE_DATA, EDIT_USER_DATA, USER_ADDRESS_DATA, EDIT_USER_ADDRESS_DATA, STATES_LIST_DATA, PINCODE_SUCCESS_DATA, SET_DEFAULT_PINCODE_DATA } from "../constants";

const INIT_STATE = {
  userLoginData: [],
  userTokenCheckData: {},
  userProfileData: {},
  editUserData: {},
  userAddressData: {},
  editUserAddressData: {},
  statesList: [],
  pincodeData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LOGIN_DATA: {
      return {
        ...state,
        userLoginData: action.data,
      };
    }
    case USER_TOKEN_CHECK_DATA: {
      return {
        ...state,
        userTokenCheckData: action.data,
      };
    }
    case USER_PROFILE_DATA: {
      return {
        ...state,
        userProfileData: action.data,
      };
    }
    case EDIT_USER_DATA: {
      return {
        ...state,
        editUserData: action.data,
      };
    }
    case USER_ADDRESS_DATA: {
      return {
        ...state,
        userAddressData: action.data,
      };
    }
    case EDIT_USER_ADDRESS_DATA: {
      return {
        ...state,
        editUserAddressData: action.data,
      };
    }
    case STATES_LIST_DATA: {
      return {
        ...state,
        statesList: action.data,
      };
    }
    case PINCODE_SUCCESS_DATA: {
      return {
        ...state,
        pincodeData: action.data,
      };
    }
    case SET_DEFAULT_PINCODE_DATA: {
      return {
        ...state,
        pincodeData: {},
      };
    }

    default:
      return state;
  }
};
