import {
    CREATE_WATCHLIST,
    REMOVE_WATCHLIST,
    REMOVE_WATCHLIST_DATA,
    GET_WATCHLIST_GAMES,
    GET_WATCHLIST_GAMES_DATA,
    GET_WATCHLIST_GAMES_ERROR,
    CREATE_WATCHLIST_DATA,
    CREATE_WATCHLIST_ERROR,
    REMOVE_WATCHLIST_ERROR,
    CHECK_WISHLIST,
    CHECK_WISHLIST_DATA,
    CHECK_WISHLIST_ERROR,
    SET_DEFAULT_WATCHLIST_GAMES,
  } from "../../constants";
  
  export const CreateWatchlist = (data) => {
    return {
      type: CREATE_WATCHLIST,
      data: data,
    };
  };
  
  export const CreateWatchlistData = (data) => {
    return {
      type: CREATE_WATCHLIST_DATA,
      data: data,
    };
  };
  
  export const CreateWatchlistError = (data) => {
    return {
      type: CREATE_WATCHLIST_ERROR,
      data: data,
    };
  };
  
  export const RemoveWatchlist = (data) => {
    return {
      type: REMOVE_WATCHLIST,
      data: data,
    };
  };
  
  export const RemoveWatchlistData = (data) => {
    return {
      type: REMOVE_WATCHLIST_DATA,
      data: data,
    };
  };
  
  export const RemoveWatchlistError = (data) => {
    return {
      type: REMOVE_WATCHLIST_ERROR,
      data: data,
    };
  };
  
  export const GetWatchlistGames = (data) => {
    return {
      type: GET_WATCHLIST_GAMES,
      data: data,
    };
  };
  
  export const GetWatchlistGamesData = (data) => {
    return {
      type: GET_WATCHLIST_GAMES_DATA,
      data: data,
    };
  };
  
  export const GetWatchlistGamesError = (data) => {
    return {
      type: GET_WATCHLIST_GAMES_ERROR,
      data: data,
    };
  };
  
  export const SetDefaultWatchlistGames = (data) => {
    return {
      type: SET_DEFAULT_WATCHLIST_GAMES
    };
  };

  export const CheckWishlist = (data) => {
    return {
      type: CHECK_WISHLIST,
      data: data,
    };
  };
  
  export const CheckWishlistData = (data) => {
    return {
      type: CHECK_WISHLIST_DATA,
      data: data,
    };
  };
  
  export const CheckWishlistError = (data) => {
    return {
      type: CHECK_WISHLIST_ERROR,
      data: data,
    };
  };
  