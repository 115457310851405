import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { 
  UserTokenCheck,
} from "../../redux/actions";
import { homepageGamesModify } from "../../utils/Common";
import MainLoader from "../../components/mainLoader/MainLoader";
import GameView from "../../components/gameVIew/GameView";
import "./GamesPage.css";
import {GetGradeGamesApi,getGameGrades} from "../../redux/api/Game/GameApis"
export default function GamesPage() {
  let { gameType } = useParams();
  const dispatch = useDispatch();
  const [headerLable, setHeaderLable] = useState('--');
  const [pageCount, setPageCount] = useState(1);
  const [gamesData, setGamesData] = useState([]);
  const [responseMsg, setResponseMsg] = useState("");
  const gameLevels = useSelector((state) => state.subjectStore.subjectListData);
  const [resultSubjectName, setResultSubjectName] = useState("");

  const handleApis = async (count) => { 
      let data= await GetGradeGamesApi({grade_id:atob(gameType),hit: count }) 
        //   const resDataModify = data?.map((data) => data.game);
      setResponseMsg(data.message)
      const modifiedFavoriteGames = homepageGamesModify(data.data);
      setGamesData([...gamesData,...modifiedFavoriteGames]);
      setPageCount(count);
  };
  const fetchMoreGames = () => { 
    handleApis(pageCount + 1);
  };   
  const isApiLoading = useSelector((state) => state.appCommon.loading);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    getGameGrades().then((rlt)=>{
      rlt.data.map((r)=>{
        if(r.grade_id==atob(gameType)){
          setHeaderLable(r.grade_name)
        }
       
      })
    })
   
  }, []);

  useEffect(() => {
    setGamesData([]);
    setPageCount(1);
    setResponseMsg("");
     
    handleApis(1);
  }, [gameType]);
  useEffect(() => {
    if (
      pageCount === 1 &&
      gamesData?.length &&
      gamesData?.length < 10 &&
      !isApiLoading
    ) {
      handleApis(2);
    }
  }, [gamesData, isApiLoading]);
  

  return (
    <div className="games-page-container">  
      <div className="games-page-header">{headerLable}</div>
      {isApiLoading && gamesData?.length === 0 && (
        <MainLoader
          key={"gamePageLoader"}
          isLoading={true}
          isFullPage={false}
        />
      )}
      {gamesData?.length ? (
        <>
           <InfiniteScroll
              dataLength={gamesData?.length || 0}
              next={fetchMoreGames}
              hasMore={responseMsg !== "Data not found"}
              loader={
                <MainLoader
                  key={"gamePageGamesListLoader"}
                  isLoading={true}
                  isFullPage={false}
                />
              }
            >
              <div className="games-page-img-container">
                {gamesData?.map((img, idx) => (
                  <div key={idx} className="games-page-img-view">
                    <GameView image={img} />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
        </>
      ) : (
        !isApiLoading && (
          <div className="games-page-no-data">No Data Available</div>
        )
      )}
    </div>
  );
}
