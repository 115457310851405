import axiosInstance from "../../../utils/Api";

export const CreateWatchlistApi = async (data) =>
  await axiosInstance
    .post("game/createwatchlist", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const RemoveWatchlistApi = async (data) =>
  await axiosInstance
    .delete("game/removewatchlist", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GetWatchlistGamesApi = async (data) =>
  await axiosInstance
    .get("game/getWatchlistGames", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const CheckWishlistApi = async (data) =>
  await axiosInstance
    .get("game/checkwatchlist", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
