import {
  SUBSCRIPTION_PLAN_LIST,
  SUBSCRIPTION_PLAN_LIST_DATA,
  SUBSCRIPTION_PLAN_LIST_ERROR,
  MY_SUBSCRIPTION,
  MY_SUBSCRIPTION_DATA,
  MY_SUBSCRIPTION_ERROR,
  MY_UPGRADE_PLAN_DATA,
  MY_UPGRADE_PLAN_DATA_ERROR
} from "../constants";

export const SubscriptionPlanList = () => {
  return {
    type: SUBSCRIPTION_PLAN_LIST,
  };
};


export const SubscriptionUpgadePlanData = (data) => {
  return {
    type: MY_UPGRADE_PLAN_DATA,
    data: data,
  };
};
export const SubscriptionUpgadePlanDataError = (data) => {
  return {
    type: MY_UPGRADE_PLAN_DATA_ERROR,
    data: data,
  };
};

export const SubscriptionPlanListData = (data) => {
  return {
    type: SUBSCRIPTION_PLAN_LIST_DATA,
    data: data,
  };
}; 

export const SubscriptionPlanListError = (data) => {
  return {
    type: SUBSCRIPTION_PLAN_LIST_ERROR,
    data: data,
  };
};

export const MySubscription = () => {
  return {
    type: MY_SUBSCRIPTION,
  };
};

export const MySubscriptionData = (data) => {
  return {
    type: MY_SUBSCRIPTION_DATA,
    data: data,
  };
};

export const MySubscriptionError = (data) => {
  return {
    type: MY_SUBSCRIPTION_ERROR,
    data: data,
  };
};
