import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserTokenCheck } from "../../redux/actions";
import Userprofile from "./Userprofile";
import "./Profile.css";

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    if (!userProfileMobile && !localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Userprofile />
    </div>
  );
}
