import React from "react";
import { Link } from "react-router-dom";
import subnavdown from "../../assets/icons/subnavdown.png";
import subnavup from "../../assets/icons/subnavup.png";
import closing from "../../assets/icons/closing.png";
import { useSelector } from "react-redux";

export default function MobileNavMenu({
  handleMobileLogin,
  setShowMobileMenu,
  menus,
  selectedMenu,
  setSelectedMenu,
  showSubMenu,
  setShowSubMenu,
  setSelectedSubMenuHeader,
  userData,
  handleLogoutUser,
  mySubscriptionDataId,
  handleCloseModal,
  upgradeButton
}) {
  const handleMenuSelect = (name) => {
    if (selectedMenu === name && showSubMenu) {
      setShowSubMenu(false);
    } else {
      setSelectedMenu(name);
      setShowSubMenu(true);
    }
  };
  const handleSubMenuSelect = (option) => {
    setSelectedSubMenuHeader(option);
    setShowMobileMenu(false);
  };
  const profileName = useSelector(
    (state) => state.userStore.userProfileData.display_name
  );

  return (
    <div className="nav-menu">
      <button
        className="modal-close-container"
        onClick={() => handleCloseModal()}
      >
        <img src={closing} className="modal-close-icon" />
      </button>
      <ul className="menu">
        {userData.mobile_number ? (
          <div className="menu-user-name">Hi {profileName} </div>
        ) : (
          <button onClick={() => handleMobileLogin()} className="menu-login">
            Login
          </button>
        )}
        {/* <Link to="/upgrade">
                <button className="nav-action-subscribe">UPGRADE</button>
      </Link> */}
        {menus?.map((menu) => (
          <li key={menu.name} className="menu-item menu-item-has-children">
            <div
              className="menu-item-name"
              onClick={() => handleMenuSelect(menu.name)}
            >
              {menu.name} <i className="plus"></i>
              {showSubMenu && selectedMenu === menu.name ? (
                <img className="img-1" src={subnavup} alt="showsubnav" />
              ) : (
                <img className="img-1" src={subnavdown} alt="showsubnav" />
              )}
            </div>

            {showSubMenu && selectedMenu === menu.name && (
              <ul className="sub-menu">
                {menu?.gamelevel?.map((option) => (
                  <li
                    key={option.name}
                    className="sub-menu-item-name"
                    onClick={() => handleSubMenuSelect(option)}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        {/* {!mySubscriptionDataId && (
          <div className="menu-item-name">
            <Link
              to="/subscribe"
              className="mobile-nav-link"
              onClick={() => setShowMobileMenu(false)}
            >
              Subscribe
            </Link>
          </div>
        )} */}
         {
              upgradeButton &&
              <div className="menu-item-name">
                <Link
                  to="/upgrade"
                  className="mobile-nav-link"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Upgrade
                </Link>
              </div> 
            }
        {userData.mobile_number && (
          <li className="menu-item">
            <div className="menu-item-name">
              <Link
                to="/profile"
                className="mobile-nav-link"
                onClick={() => setShowMobileMenu(false)}
              >
                Edit Profile
              </Link>
            </div>
            <div className="menu-item-name">
              <Link
                to="/games/favourites"
                className="mobile-nav-link"
                onClick={() => setShowMobileMenu(false)}
              >
                Favourites
              </Link>
            </div>
            <div className="menu-item-name">
              <Link
                to="/games/playlist"
                className="mobile-nav-link"
                onClick={() => setShowMobileMenu(false)}
              >
                Playlist
              </Link>
            </div>
            <div className="menu-item-name" onClick={() => handleLogoutUser()}>
              Logout
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}