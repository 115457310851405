import {
  CREATE_FAVORITE_GAME_DATA,
  GET_FAVORITE_GAMES_DATA,
  REMOVE_FAVORITE_GAME_DATA,
  SET_DEFAULT_FAVORITE_GAMES,
} from "../../constants";

const INIT_STATE = {
  createFavoriteGameData: {},
  removeFavoriteGameData: {},
  favoriteGamesData: [],
  favoriteGamesMsg: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_FAVORITE_GAME_DATA: {
      return {
        ...state,
        createFavoriteGameData: action.data,
      };
    }
    case REMOVE_FAVORITE_GAME_DATA: {
      return {
        ...state,
        removeFavoriteGameData: action.data,
      };
    }
    case GET_FAVORITE_GAMES_DATA: {
      return {
        ...state,
        favoriteGamesData: [...state.favoriteGamesData, ...action.data.data],
        favoriteGamesMsg: action.data.message,
      };
    }
    case SET_DEFAULT_FAVORITE_GAMES: {
      return {
        ...state,
        favoriteGamesData: [],
        favoriteGamesMsg: "",
      };
    }

    default:
      return state;
  }
};
