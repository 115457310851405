import {
  USER_LOGIN,
  USER_LOGIN_DATA,
  USER_LOGIN_ERROR,
  USER_TOKEN_CHECK,
  USER_TOKEN_CHECK_DATA,
  USER_TOKEN_CHECK_ERROR,
  USER_PROFILE,
  USER_PROFILE_DATA,
  USER_PROFILE_ERROR,
  EDIT_USER,
  EDIT_USER_DATA,
  EDIT_USER_ERROR,
  EDIT_USER_ADDRESS,
  EDIT_USER_ADDRESS_DATA,
  EDIT_USER_ADDRESS_ERROR,
  USER_ADDRESS,
  USER_ADDRESS_DATA,
  USER_ADDRESS_ERROR,
  STATES_LIST,
  STATES_LIST_DATA,
  STATES_LIST_ERROR,
  PINCODE_DATA,
  PINCODE_DATA_ERROR,
  PINCODE_SUCCESS_DATA,
  SET_DEFAULT_PINCODE_DATA,
} from "../constants";

export const UserLogin = (data) => {
  return {
    type: USER_LOGIN,
    data: data,
  };
};

export const UserLoginData = (data) => {
  return {
    type: USER_LOGIN_DATA,
    data: data,
  };
};

export const UserLoginError = (data) => {
  return {
    type: USER_LOGIN_ERROR,
    data: data,
  };
};

export const UserTokenCheck = () => {
  return {
    type: USER_TOKEN_CHECK,
  };
};

export const UserTokenCheckData = (data) => {
  return {
    type: USER_TOKEN_CHECK_DATA,
    data: data,
  };
};

export const UserTokenCheckError = (data) => {
  return {
    type: USER_TOKEN_CHECK_ERROR,
    data: data,
  };
};

export const UserProfile = () => {
  return {
    type: USER_PROFILE,
  };
};

export const UserProfileData = (data) => {
  return {
    type: USER_PROFILE_DATA,
    data: data,
  };
};

export const UserProfileError = (data) => {
  return {
    type: USER_PROFILE_ERROR,
    data: data,
  };
};

export const EditUser = (data) => {
  return {
    type: EDIT_USER,
    data: data,
  };
};

export const EditUserData = (data) => {
  return {
    type: EDIT_USER_DATA,
    data: data,
  };
};

export const EditUserError = (data) => {
  return {
    type: EDIT_USER_ERROR,
    data: data,
  };
};

export const UserAddress = () => {
  return {
    type: USER_ADDRESS,
  };
};

export const UserAddressData = (data) => {
  return {
    type: USER_ADDRESS_DATA,
    data: data,
  };
};

export const UserAddressError = (data) => {
  return {
    type: USER_ADDRESS_ERROR,
    data: data,
  };
};

export const EditUserAddress = (data) => {
  return {
    type: EDIT_USER_ADDRESS,
    data: data,
  };
};

export const EditUserAddressData = (data) => {
  return {
    type: EDIT_USER_ADDRESS_DATA,
    data: data,
  };
};

export const EditUserAddressError = (data) => {
  return {
    type: EDIT_USER_ADDRESS_ERROR,
    data: data,
  };
};

export const StatesList = () => {
  return {
    type: STATES_LIST,
  };
};

export const StatesListData = (data) => {
  return {
    type: STATES_LIST_DATA,
    data: data,
  };
};

export const StatesListError = (data) => {
  return {
    type: STATES_LIST_ERROR,
    data: data,
  };
};

export const PincodeData = (data) => {
  return {
    type: PINCODE_DATA,
    data: data,
  };
};

export const PincodeSuccessData = (data) => {
  return {
    type: PINCODE_SUCCESS_DATA,
    data: data,
  };
};

export const PincodeDataError = (data) => {
  return {
    type: PINCODE_DATA_ERROR,
    data: data,
  };
};

export const SetDefaultPincodeData = () => {
  return {
    type: SET_DEFAULT_PINCODE_DATA,
  };
};
