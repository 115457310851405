import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import play from "../../assets/icons/play.png";
import favourite from "../../assets/icons/favourite.png";
import favouriteactive from "../../assets/icons/favouriteactive.png";
import playlistadd from "../../assets/icons/playlistadd.png";
import playlistactive from "../../assets/icons/playlistactive.png";
import share from "../../assets/icons/share.png";
import { getEnvUrl } from "../../utils/Api";
import {
  CreateFavoriteGame,
  CreateWatchlist,
  CheckWishlist,
  RemoveFavoriteGame,
  RemoveWatchlist,
} from "../../redux/actions";
import { encodedId } from "../../utils/Common";
import "./GameView.css";
 

export default function GradeView({ image, showActions = true }) {
  const [isHovered, setIsHovered] = useState(false);  
  const handleMouseEnter = () => {
    setIsHovered(true);
  }; 
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  const checkWishlistData = useSelector(
    (state) => state.gameStore.gameWatchlistData.checkWishlistData
  );
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  const handleGameClick = (e, id) => {
    // grade
    navigate(`/grade/${btoa(id)}`)     
  };
  

  return (
    <>
      <button
        className={imageLoaded ? isHovered ? "corousal-image-view enlarged-button" : "corousal-image-view" : "hide-image"}
        
        onClick={(e) => handleGameClick(e, image.grade_id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={
            userProfileMobile && showActions
              ? "corousal-image-actions"
              : "corousal-image-actions no-actions"
          }
        > 
        </div>
        
        <img
          src={`${getEnvUrl()}game/img?type=v&id=TkRVek5BPT0=&width=300`}
          alt={image.grade_name}
          className="corousal-image"
          onLoad={() => setImageLoaded(true)}
          onError={() => console.log("error loading image")}
        />
        <div className="corousal-image-data-title">{image.grade_name}</div>
      </button>
      <div className={imageLoaded ? "hide-image" : "loading-image"}></div>
    </>
  );
}
