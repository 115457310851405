import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FetchError,
  FetchStart,
  FetchSuccess,
  GameBannersData,
  GameDetailsData,
  GetCategoryGamesData,
  GetRecommendedGamesData,
  HomePageGamesData,
  PlayGameData,
  SearchGamesData,
  GetGradeGames
} from "../../actions";
import {
  GameBannersApi,
  GameDetailsApi,
  GetCategoryGamesApi,
  GetRecommendedGamesApi,
  HomePageGamesApi,
  PlayGameApi,
  SearchGamesApi,
  GetGradeGamesApi
} from "../../api";
import {
  GAME_BANNERS,
  GAME_DETAILS,
  GET_CATEGORY_GAMES,
  GET_RECOMMENDED_GAMES,
  HOME_PAGE_GAMES,
  PLAY_GAME,
  SEARCH_GAMES,
  GET_GRADE_GAMES
} from "../../constants";


function* GameGradeService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(GetGradeGamesApi, data);
    if (res) {
      yield put(GetGradeGames(res));
    } else {
    } 
  } catch (error) {}
}

function* GameDetailsService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(GameDetailsApi, data);
    if (res) {
      yield put(GameDetailsData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* GetCategoryGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(GetCategoryGamesApi, data);
    if (res) {
      yield put(GetCategoryGamesData(res));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {
    yield put(FetchError());
  }
}

function* GameBannersService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(GameBannersApi, data);
    if (res) {
      yield put(GameBannersData(res.data));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {}
}

function* HomePageGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(HomePageGamesApi, data);
    if (res) {
      yield put(HomePageGamesData(res));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {}
}

function* GetRecommendedGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(GetRecommendedGamesApi, data);
    if (res) {
      yield put(GetRecommendedGamesData(res.data));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {}
}

function* SearchGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(SearchGamesApi, data);
    if (res) {
      yield put(SearchGamesData(res.data));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {
    yield put(FetchError());
  }
}

function* PlayGameService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(PlayGameApi, data);
    if (res) {
      yield put(PlayGameData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {
    // yield put(FetchError());
  }
}

export function* GameDetailsSaga() {
  yield takeEvery(GAME_DETAILS, GameDetailsService);
}

export function* GetCategoryGamesSaga() {
  yield takeEvery(GET_CATEGORY_GAMES, GetCategoryGamesService);
}

export function* GameBannersSaga() {
  yield takeEvery(GAME_BANNERS, GameBannersService);
}

export function* GameGradeSaga() {
  yield takeEvery(GET_GRADE_GAMES, GameGradeService);
}

export function* HomePageGamesSaga() {
  yield takeEvery(HOME_PAGE_GAMES, HomePageGamesService);
}

export function* GetRecommendedGamesSaga() {
  yield takeEvery(GET_RECOMMENDED_GAMES, GetRecommendedGamesService);
}

export function* SearchGamesSaga() {
  yield takeEvery(SEARCH_GAMES, SearchGamesService);
}

export function* PlayGameSaga() {
  yield takeEvery(PLAY_GAME, PlayGameService);
}

export default function* rootSaga() {
  yield all([
    fork(GameDetailsSaga),
    fork(GetCategoryGamesSaga),
    fork(GameBannersSaga),
    fork(HomePageGamesSaga),
    fork(GetRecommendedGamesSaga),
    fork(SearchGamesSaga),
    fork(PlayGameSaga),
    fork(GameGradeSaga)
  ]);
}
