import {all} from "redux-saga/effects";
import GameSagas from "./GameSagas";
import GameFavouriteSagas from "./GameFavouriteSagas";
import GameWatchlistSagas from "./GameWatchlistSagas";
import GameReviewSagas from "./GameReviewSagas";

export default function* rootSaga() {
  yield all([
    GameSagas(),
    GameFavouriteSagas(),
    GameWatchlistSagas(),
    GameReviewSagas(),
  ]);
}
