import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 } from 'uuid';
import { ShowToastMessage, UserLogin } from "../../redux/actions";
import { auth } from "../../firebase-config";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import loginbackground from "../../assets/images/loginbackground.png";
import phone from "../../assets/icons/phone.png";
import close from "../../assets/icons/close.png";
import { formatPhoneNumber, validatePhoneNumber } from "../../utils/Common";
import "./Login.css";
import {UserAuthApi} from "../../redux/api/UserApis"

export default function Login({
  showOtp,
  setShowOtp,
  setIsLoading,
  handleLoginModalClose,
  handleCloseModal
}) {
  // const uid = new ShortUniqueId({ length: 8 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mynumber, setnumber] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [otpVal, setOtpVal] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [isNumberError, setIsNumberError] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);
  // const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [countryCode, setCountryCode] = useState('+91');
  const [imageLoaded, setImageLoaded] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        // if (minutes === 0) {clearInterval(interval)}
        // else {setSeconds(59); setMinutes(minutes - 1);}
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  useEffect(() => {
    if (showOtp) {
      document.getElementById("otpInput1").focus();
    }
  }, [showOtp]);
  const handleChange = (value, event) => {
    const val = event.target.value;
    if (Number.isInteger(Number.parseInt(val))) {
      setOtpVal({ ...otpVal, [value]: val.charAt(val.length - 1) });
    }
  };
  const inputfocus = (elmnt) => {
    const tabId = elmnt.target.tabIndex;
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      setOtpVal({ ...otpVal, [`otp${tabId}`]: "" });
      if (tabId > 1) {
        document.getElementById(`otpInput${tabId - 1}`).focus();
      }
    } else if (Number.isInteger(Number.parseInt(elmnt.key))) {
      if (tabId < 6) {
        document.getElementById(`otpInput${tabId + 1}`).focus();
      }
    }
  };
  const handleInputNumber = (input,type) => { 
    if(type=="email"){
      setEmail(input.trim())
    }else if(type=="password"){
      setPw(input.trim())
    } 
  };
const signin =async  () => { 
  const deviceID = v4();
  let userLoginRes=await UserAuthApi({
    "email":email,
    "password": pw,
    "deviceID": deviceID,
    "lat": "",
    "lag": ""
    }) 
    setIsLoading(false);
    if(userLoginRes.status){
      const tokenData = `Bearer ${userLoginRes.data[0].token}`;
      const userid = userLoginRes.data[0].userid;
      localStorage.setItem("token", tokenData);
      localStorage.setItem("userid", userid); 
      dispatch(ShowToastMessage('Login successfully'));
      setTimeout(function() {
        window.location.href = '/';
      }, 2000);
      
    }else{
      dispatch(ShowToastMessage(userLoginRes?.message));
    }


  };

  // const signin = () => {
  //   if (mynumber === "" || mynumber.length !== 10) return;
  //   onCaptchaVerify();
  //   const formatNumber = formatPhoneNumber(`${countryCode}${mynumber}`);
  //   // const formatNumber = '+1 650-555-3434';
  //   const appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, formatNumber, appVerifier)
  //     .then(function (confirmationResult) {
  //       window.confirmationResult = confirmationResult;
  //       setShowOtp(true);
  //       setIsLoading(false);
  //     })
  //     .catch(function (error) {
  //       console.log(
  //         "Error from login firebase",
  //         error,
  //         error.message,
  //         error.code
  //       );
  //       let mobileErrorMsg = "Something went wrong. Please try again";
  //       if(error.code === "auth/invalid-phone-number") {
  //         mobileErrorMsg = "Mobile number is not valid";
  //       }
  //       dispatch(ShowToastMessage(mobileErrorMsg));
  //       setIsLoading(false);
  //     });
  // };
  function onCaptchaVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {},
        },
        auth
      );
    }
  }
  const validateOtp = () => {
    const otpValue = Object.values(otpVal)?.join("");
    if (otpValue.length === 6) {
      setIsLoading(true);
      const deviceID = v4();
      localStorage.setItem("deviceID", deviceID);
      window.confirmationResult
        .confirm(otpValue)
        .then(async (res) => {
          const loginDetails = {
            countryCode: "91",
            mobileNumber: mynumber,
            deviceID: deviceID,
          };
          dispatch(UserLogin(loginDetails));
          setShowOtp(false);
          handleLoginModalClose();
          setIsLoading(false);
          dispatch(ShowToastMessage('Login successfully'));
          setTimeout(function() {
            window.location.href = '/';
        }, 2000);
        setTimeout(function() {
          window.location.reload();
      }, 10000);
        })
        .catch((error) => {
          console.log(
            "Error from OTP firebase",
            error,
            error.message,
            error.code
          );
          let otpErrorMsg = "Something went wrong. Please try again";
          if(error.code === "auth/invalid-verification-code") {
            otpErrorMsg = "Invalid OTP. Please try again";
          }
          dispatch(ShowToastMessage(otpErrorMsg));
          setIsLoading(false);
        });
    } else {
      setIsOtpError(true);
    }
  };
  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };
  const handleLoginUser = () => {
    setIsNumberError(false);
    if(pw.length!=0 && email.length!=0 && validateEmail(email)) {
        setIsLoading(true);
        signin();
    }else{
      setIsNumberError(true);
    }

    // if (validatePhoneNumber(mynumber)) {
    //   setIsLoading(true);
    //   signin();
    // } else {
    //   setIsNumberError(true);
    // }
  };

  // const handleLoginUser = () => {
  //   if (validatePhoneNumber(mynumber)) {
  //     setIsLoading(true);
  //     signin();
  //   } else {
  //     setIsNumberError(true);
  //   }
  // };
  const handleResendOtp = () => {
    if(seconds === 0) {
      signin();
      setSeconds(59);
    }
  }
  const handleCountryCode = (val) => {
    setCountryCode(val);
  }

  return (
    <div className="login-modal-container">
      <button
        className="modal-close-container"
        onClick={() => handleCloseModal()}
      >
        <img src={close} className="modal-close-icon" />
      </button>
      <div className="login-container">
        <div id="recaptcha-container"></div>
        <div className="login-title">Login</div>
        {!showOtp ? (
          <div className="login-enter-number-container"> 
            {/* <div className="login-desc">Enter Mobile Number</div>
            <div className="login-input-container">
              <img src={phone} className="login-input-icon" />
              <select className="country-code-dropdown" onChange={e => handleCountryCode(e.target.value)}>
                <option value={'+91'}>+91</option>
                <option value={'+1'}>+1</option>
              </select>
              <input
                type="number"
                className="login-input"
                placeholder="Enter Mobile Number"
                onChange={(e) => handleInputNumber(e.target.value)}
              />
            </div> */}

            <div className="login-input-container"> 
              <input
                type="text"
                className="login-input"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => handleInputNumber(e.target.value,'email')}
              />
            </div>
            <div className="login-input-container"> 
               <input
                type={!isPassword?"password":"text"}
                className="login-input"
                placeholder="Password"
                value={pw}
                onChange={(e) => handleInputNumber(e.target.value,'password')}
              /> 
            </div>
            <div class="checkbox-container">
                <input type="checkbox" id="myCheckbox" checked={isPassword}
                onClick={()=>{
                  setIsPassword(!isPassword)
                }}/>
                <label class="custom-checkbox" for="myCheckbox">Show password</label>
            </div>

            {isNumberError && (
              <div className="login-number-error">
                Please enter the valid email and password
              </div>
            )}
            <button
              className="login-input-button"
              onClick={() => handleLoginUser()}
            >
              Login
            </button>
          </div>
        ) : (
          <div className="login-enter-otp-container">
            <div className="login-desc">Verify OTP, Send To {mynumber} </div>
            <div className="login-input-otp-container">
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp1}
                onChange={(e) => handleChange("otp1", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput1"
                tabIndex="1"
              />
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp2}
                onChange={(e) => handleChange("otp2", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput2"
                tabIndex="2"
              />
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp3}
                onChange={(e) => handleChange("otp3", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput3"
                tabIndex="3"
              />
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp4}
                onChange={(e) => handleChange("otp4", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput4"
                tabIndex="4"
              />
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp5}
                onChange={(e) => handleChange("otp5", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput5"
                tabIndex="5"
              />
              <input
                type="number"
                className="login-otp-input"
                autoComplete="off"
                value={otpVal?.otp6}
                onChange={(e) => handleChange("otp6", e)}
                onKeyUp={(e) => inputfocus(e)}
                id="otpInput6"
                tabIndex="6"
              />
            </div>
            <div>
              <p className="login-timer-view">
                Remaining time:{" "}
                <span className="login-timer">
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
            </div>
            {isOtpError && (
              <div className="login-otp-error">please enter the valid OTP</div>
            )}
            <button
              className="login-input-button"
              onClick={() => validateOtp()}
            >
              VERIFY OTP
            </button>
            <div className="login-resend">
              Didn't Receive OTP?{" "}
              <button
                className={`login-resend-button ${
                  seconds !== 0 && "disable-resend-button"
                }`}
                onClick={() => handleResendOtp()}
              >
                Resend
              </button>
            </div>
          </div>
        )}
      </div>
      <img
        src={loginbackground}
        alt="login view"
        className={imageLoaded ? "login-modal-background-image" : "hide-login-img-visibility"}
        onLoad={() => setImageLoaded(true)}
        onError={() => console.log("login image loading error")}
      />
      <div
          className={imageLoaded ? "hide-login-img-visibility" : "sample-login-background"}
      ></div>
    </div>
  );
}
