import {
  GAME_BANNERS,
  GAME_BANNERS_DATA,
  GAME_BANNERS_ERROR,
  GET_CATEGORY_GAMES,
  GET_CATEGORY_GAMES_DATA,
  GET_CATEGORY_GAMES_ERROR,
  GAME_DETAILS,
  GAME_DETAILS_DATA,
  GAME_DETAILS_ERROR,
  HOME_PAGE_GAMES,
  HOME_PAGE_GAMES_DATA,
  HOME_PAGE_GAMES_ERROR,
  GET_RECOMMENDED_GAMES,
  GET_RECOMMENDED_GAMES_DATA,
  GET_RECOMMENDED_GAMES_ERROR,
  SEARCH_GAMES,
  SEARCH_GAMES_DATA,
  SEARCH_GAMES_ERROR,
  SET_DEFAULT_HOME_PAGE_GAMES,
  SET_DEFAULT_CATEGORY_GAMES,
  PLAY_GAME,
  PLAY_GAME_DATA,
  PLAY_GAME_ERROR,
  SET_DEFAULT_PLAY_GAME_DATA,
  SET_DEFAULT_BANNERS_GAMES,
  GET_GRADE_GAMES
} from "../../constants";

export const GameDetails = (data) => {
  return {
    type: GAME_DETAILS,
    data: data,
  };
};

export const GameDetailsData = (data) => {
  return {
    type: GAME_DETAILS_DATA,
    data: data,
  };
};

export const GameDetailsError = (data) => {
  return {
    type: GAME_DETAILS_ERROR,
    data: data,
  };
};

export const GetCategoryGames = (data) => {
  return {
    type: GET_CATEGORY_GAMES,
    data: data,
  };
};

export const GetCategoryGamesData = (data) => {
  return {
    type: GET_CATEGORY_GAMES_DATA,
    data: data,
  };
};

export const GetCategoryGamesError = (data) => {
  return {
    type: GET_CATEGORY_GAMES_ERROR,
    data: data.data,
  };
};

export const SetDefaultCategoryGames = () => {
  return {
    type: SET_DEFAULT_CATEGORY_GAMES
  };
};

export const GameBanners = (data) => {
  return {
    type: GAME_BANNERS,
    data: data,
  };
};

export const GameBannersData = (data) => {
  return {
    type: GAME_BANNERS_DATA,
    data: data,
  };
};

export const GameBannersError = (data) => {
  return {
    type: GAME_BANNERS_ERROR,
    data: data,
  };
};

export const SetDefaultBannerGames = () => {
  return {
    type: SET_DEFAULT_BANNERS_GAMES
  };
};

export const HomePageGames = (data) => {
  return {
    type: HOME_PAGE_GAMES,
    data: data,
  };
};

export const HomePageGamesData = (data) => {
  return {
    type: HOME_PAGE_GAMES_DATA,
    data: data,
  };
};

export const HomePageGamesError = (data) => {
  return {
    type: HOME_PAGE_GAMES_ERROR,
    data: data,
  };
};

export const SetDefaultHomePageGames = () => {
  return {
    type: SET_DEFAULT_HOME_PAGE_GAMES
  };
};

export const GetRecommendedGames = (data) => {
  return {
    type: GET_RECOMMENDED_GAMES,
    data: data,
  };
};

export const GetRecommendedGamesData = (data) => {
  return {
    type: GET_RECOMMENDED_GAMES_DATA,
    data: data,
  };
};

export const GetRecommendedGamesError = (data) => {
  return {
    type: GET_RECOMMENDED_GAMES_ERROR,
    data: data,
  };
};

export const SearchGames = (data) => {
  return {
    type: SEARCH_GAMES,
    data: data,
  };
};

export const SearchGamesData = (data) => {
  return {
    type: SEARCH_GAMES_DATA,
    data: data,
  };
};

export const SearchGamesError = (data) => {
  return {
    type: SEARCH_GAMES_ERROR,
    data: data,
  };
};

export const PlayGame = (data) => {
  return {
    type: PLAY_GAME,
    data: data,
  };
};

export const PlayGameData = (data) => {
  return {
    type: PLAY_GAME_DATA,
    data: data,
  };
};

export const PlayGameError = (data) => {
  return {
    type: PLAY_GAME_ERROR,
    data: data,
  };
};

export const SetDefaultPlayGameData = () => {
  return {
    type: SET_DEFAULT_PLAY_GAME_DATA
  };
};
export const GetGradeGames = (data) => {
  return {
    type: GET_GRADE_GAMES,
    data: data,
  };
};