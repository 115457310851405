import axiosInstance from "../../utils/Api";

export const OnlinePaymentApi = async (data) =>
  await axiosInstance
    .post("payment/onlinepayment", data)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const PaymentResponseApi = async (data) =>
  await axiosInstance
    .post("payment/onlinepaymentresponse", data)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const PaymentHistoryApi = async (data) =>
  await axiosInstance
    .get("payment/history", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const PaymentReceiptApi = async (data) =>
  await axiosInstance
    .get("payment/getPaymentReceipt", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
