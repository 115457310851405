import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import play from "../../assets/icons/play.png";
import favourite from "../../assets/icons/favourite.png";
import favouriteactive from "../../assets/icons/favouriteactive.png";
import playlistadd from "../../assets/icons/playlistadd.png";
import playlistactive from "../../assets/icons/playlistactive.png";
import share from "../../assets/icons/share.png";
import {
  CreateFavoriteGame,
  CreateWatchlist,
  CheckWishlist,
  RemoveFavoriteGame,
  RemoveWatchlist,
} from "../../redux/actions";
import { encodedId } from "../../utils/Common";
import "./GameViewH.css"; 
export default function GameView({ image, showActions = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);
  const checkWishlistData = useSelector(
    (state) => state.gameStore.gameWatchlistData.checkWishlistData
  );
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  const handleGameClick = (e, id) => {
    if (e.target.classList.contains("corousal-favourite-action")) {
      if (checkWishlistData[image.id]?.favourite) {
        dispatch(RemoveFavoriteGame({ game_id: Number.parseInt(id) }));
      } else {
        dispatch(CreateFavoriteGame({ game_id: Number.parseInt(id) }));
      }
    } else if (e.target.classList.contains("corousal-playlistadd-action")) {
      if (checkWishlistData[image.id]?.watchlist) {
        dispatch(RemoveWatchlist({ game_id: Number.parseInt(id) }));
      } else {
        dispatch(CreateWatchlist({ game_id: Number.parseInt(id) }));
      }
    } else {
      navigate(`/game-detail/${id}`);
    }
  };
  const handleCheckWishlist = (id) => {
    if (!checkWishlistData[id] && userProfileMobile) {
      dispatch(CheckWishlist({ game_id: encodedId(id), id: id }));
    }
  };
  
  const [isHovered, setIsHovered] = useState(false);  
  const handleMouseEnter = () => {
    setIsHovered(true);
  }; 
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <button
        className={imageLoaded ? isHovered ? "corousal-image-view enlarged-button" : "corousal-image-view" : "hide-image"}
        // onMouseOver={() => handleCheckWishlist(image.id)}
        onClick={(e) => handleGameClick(e, image.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={
            userProfileMobile && showActions
              ? "corousal-image-actions"
              : "corousal-image-actions no-actions"
          }
        >
          {userProfileMobile && showActions && (
            <div className="corousal-image-social-actions">
              <span className="corousal-playlistadd-btn action-btn">
                {checkWishlistData[image.id]?.watchlist ? (
                  <img
                    src={playlistactive}
                    alt="playlistadd-action"
                    className="corousal-playlistadd-action"
                  />
                ) : (
                  <img
                    src={playlistadd}
                    alt="playlistadd-action"
                    className="corousal-playlistadd-action"
                  />
                )}
              </span>
              <span className="corousal-favourite-btn action-btn">
                {checkWishlistData[image.id]?.favourite ? (
                  <img
                    src={favouriteactive}
                    alt="favourite-action"
                    className="corousal-favourite-action"
                  />
                ) : (
                  <img
                    src={favourite}
                    alt="favourite-action"
                    className="corousal-favourite-action"
                  />
                )}
              </span>
              {/* For next versions */}
              {/* <button
              className="corousal-share-btn action-btn"
              onClick={() => handleShareClick(image.id)}
            >
              <img
                src={share}
                alt="share-action"
                className="corousal-share-action"
              />
            </button> */}
            </div>
          )}
          {/* <div className="corousal-play-action">
            <img src={play} alt="play-action" />
          </div>
          <div className="corousal-image-data">
            <div className="corousal-image-data-title">{image.name}</div>
            <div className="corousal-image-data-lang">{image.description}</div>
          </div> */}
        </div>
        {/* <div
          className="corousal-image-planview"
          style={{
            backgroundColor: image.subscriptionplans?.background_color
          }}
        >
          {image.subscriptionplans?.plan_name}  
        </div> */}
        <img
          src={`${image.gamethumbnails}&width=300`}
          alt={image.name}
          className="corousal-image"
          onLoad={() => setImageLoaded(true)}
          onError={() => console.log("error loading image")}
        />
         <div className="corousal-image-data-title">{image.name}</div>
      </button>
      <div className={imageLoaded ? "hide-image" : "loading-image"}></div>
    </>
  );
}
