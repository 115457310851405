export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_DATA = "USER_LOGIN_DATA";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_TOKEN_CHECK = "USER_TOKEN_CHECK";
export const USER_TOKEN_CHECK_DATA = "USER_TOKEN_CHECK_DATA";
export const USER_TOKEN_CHECK_ERROR = "USER_TOKEN_CHECK_ERROR";
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_DATA = "EDIT_USER_DATA";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
export const USER_ADDRESS = "USER_ADDRESS";
export const USER_ADDRESS_DATA = "USER_ADDRESS_DATA";
export const USER_ADDRESS_ERROR = "USER_ADDRESS_ERROR";
export const EDIT_USER_ADDRESS = "EDIT_USER_ADDRESS";
export const EDIT_USER_ADDRESS_DATA = "EDIT_USER_ADDRESS_DATA";
export const EDIT_USER_ADDRESS_ERROR = "EDIT_USER_ADDRESS_ERROR";
export const STATES_LIST = "STATES_LIST";
export const STATES_LIST_DATA = "STATES_LIST_DATA";
export const STATES_LIST_ERROR = "STATES_LIST_ERROR";
export const PINCODE_DATA = "PINCODE_DATA";
export const PINCODE_SUCCESS_DATA = "PINCODE_SUCCESS_DATA";
export const PINCODE_DATA_ERROR = "PINCODE_DATA_ERROR";
export const SET_DEFAULT_PINCODE_DATA = "SET_DEFAULT_PINCODE_DATA";
