import React, { useEffect } from "react";
import "./Modal.css";

export default function Modal({
  className,
  showModal,
  handleCloseModal,
  children,
}) {
  useEffect(() => {
    if (showModal) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains("modal-container")) {
        event.preventDefault();
        handleCloseModal();
      }
    };
    const handleKeyClick = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        handleCloseModal();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleKeyClick, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleKeyClick, true);
    };
  });
  if (!showModal) {
    return;
  }
  return (
    <div className={`${className ? className : ""} modal-container`}>
      {React.cloneElement(children, { handleCloseModal: handleCloseModal })}
    </div>
  );
}
