import { SUBJECT_LIST_DATA } from "../constants";

const INIT_STATE = {
  subjectListData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBJECT_LIST_DATA: {
      return {
        ...state,
        subjectListData: action.data,
      };
    }

    default:
      return state;
  }
};
