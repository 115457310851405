import {
  SUBJECT_LIST,
  SUBJECT_LIST_DATA,
  SUBJECT_LIST_ERROR,
} from "../constants";

export const SubjectList = (data) => {
  return {
    type: SUBJECT_LIST,
    data: data,
  };
};

export const SubjectListData = (data) => {
  return {
    type: SUBJECT_LIST_DATA,
    data: data,
  };
};

export const SubjectListError = (data) => {
  return {
    type: SUBJECT_LIST_ERROR,
    data: data,
  };
};
