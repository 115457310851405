import {
    CREATE_FAVORITE_GAME,
    CREATE_FAVORITE_GAME_DATA,
    CREATE_FAVORITE_GAME_ERROR,
    REMOVE_FAVORITE_GAME,
    REMOVE_FAVORITE_GAME_DATA,
    REMOVE_FAVORITE_GAME_ERROR,
    GET_FAVORITE_GAMES,
    GET_FAVORITE_GAMES_DATA,
    GET_FAVORITE_GAMES_ERROR,
    SET_DEFAULT_FAVORITE_GAMES,
  } from "../../constants";
  
  export const CreateFavoriteGame = (data) => {
    return {
      type: CREATE_FAVORITE_GAME,
      data: data,
    };
  };
  
  export const CreateFavoriteGameData = (data) => {
    return {
      type: CREATE_FAVORITE_GAME_DATA,
      data: data,
    };
  };
  
  export const CreateFavoriteGameError = (data) => {
    return {
      type: CREATE_FAVORITE_GAME_ERROR,
      data: data,
    };
  };
  
  export const RemoveFavoriteGame = (data) => {
    return {
      type: REMOVE_FAVORITE_GAME,
      data: data,
    };
  };
  
  export const RemoveFavoriteGameData = (data) => {
    return {
      type: REMOVE_FAVORITE_GAME_DATA,
      data: data,
    };
  };
  
  export const RemoveFavoriteGameError = (data) => {
    return {
      type: REMOVE_FAVORITE_GAME_ERROR,
      data: data,
    };
  };
  
  export const GetFavoriteGames = (data) => {
    return {
      type: GET_FAVORITE_GAMES,
      data: data,
    };
  };
  
  export const GetFavoriteGamesData = (data) => {
    return {
      type: GET_FAVORITE_GAMES_DATA,
      data: data,
    };
  };
  
  export const GetFavoriteGamesError = (data) => {
    return {
      type: GET_FAVORITE_GAMES_ERROR,
      data: data,
    };
  };
  
  export const SetDefaultFavoriteGames = (data) => {
    return {
      type: SET_DEFAULT_FAVORITE_GAMES
    };
  };
  