import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  GetCategoryGames,
  GetFavoriteGames,
  GetWatchlistGames,
  SearchGames,
  SetDefaultCategoryGames,
  SetDefaultFavoriteGames,
  SetDefaultWatchlistGames,
  UserTokenCheck,
} from "../../redux/actions";
import { homepageGamesModify } from "../../utils/Common";
import MainLoader from "../../components/mainLoader/MainLoader";
import GameView from "../../components/gameVIew/GameView";
import "./GamesPage.css";

export default function GamesPage() {
  let { gameType } = useParams();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [gamesData, setGamesData] = useState([]);
  const [responseMsg, setResponseMsg] = useState("");
  const gameLevels = useSelector((state) => state.subjectStore.subjectListData);
  const [resultSubjectName, setResultSubjectName] = useState("");

  const handleApis = (count) => {
    if (gameType === "favourites") {
      dispatch(GetFavoriteGames({ hit: count }));
    } else if (gameType === "playlist") {
      dispatch(GetWatchlistGames({ hit: count }));
    } else if (gameType.includes("subject")) {
      const levelId = gameType.split("-")[1];
      dispatch(GetCategoryGames({ level_id: levelId, hit: count }));
      const gameLevelData = gameLevels?.map((level) => level.gamelevel);
      const [eng = [], math = []] = gameLevelData;
      const gameDataArray = [...eng, ...math];
      let resultSubject = gameDataArray.filter((level) => level.id === levelId);
      setResultSubjectName(resultSubject[0]?.name);
    } else {
      const searchText = gameType.split("-")[1];
      dispatch(SearchGames({ term: searchText }));
    }
    setPageCount(count);
  };
  const fetchMoreGames = () => {
    // setPageCount(pageCount + 1);
    handleApis(pageCount + 1);
  };
  const { watchlistGamesData, watchlistGamesMsg } = useSelector(
    (state) => state.gameStore.gameWatchlistData
  );
  const { favoriteGamesData, favoriteGamesMsg } = useSelector(
    (state) => state.gameStore.gameFavouriteData
  );
  const { categoryGamesData, categoryGamesMessage, searchGamesData } =
    useSelector((state) => state.gameStore.gameData);
  const isApiLoading = useSelector((state) => state.appCommon.loading);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
  }, []);

  useEffect(() => {
    setGamesData([]);
    setPageCount(1);
    setResponseMsg("");
    if (gameType === "favourites") {
      dispatch(SetDefaultFavoriteGames());
    } else if (gameType === "playlist") {
      dispatch(SetDefaultWatchlistGames());
    } else if (gameType.includes("subject")) {
      dispatch(SetDefaultCategoryGames());
    } else {
      // In case of search page lazy loading
    }
    handleApis(1);
  }, [gameType]);
  useEffect(() => {
    if (
      pageCount === 1 &&
      gamesData?.length &&
      gamesData?.length < 10 &&
      !isApiLoading
    ) {
      handleApis(2);
    }
  }, [gamesData, isApiLoading]);
  useEffect(() => {
    if (gameType === "favourites") {
      const resDataModify = favoriteGamesData?.map((data) => data.game);
      const modifiedFavoriteGames = homepageGamesModify(resDataModify);
      setGamesData(modifiedFavoriteGames);
    } else if (gameType === "playlist") {
      const resDataModify = watchlistGamesData?.map((data) => data.game);
      const modifiedWatchlistGames = homepageGamesModify(resDataModify);
      setGamesData(modifiedWatchlistGames);
    } else if (gameType.includes("subject")) {
      const modifiedCategoryGames = homepageGamesModify(categoryGamesData);
      setGamesData(modifiedCategoryGames);
    } else {
      const modifiedSearchGames = homepageGamesModify(searchGamesData);
      setGamesData(modifiedSearchGames);
    }
  }, [
    watchlistGamesData,
    favoriteGamesData,
    categoryGamesData,
    searchGamesData,
  ]);
  useEffect(() => {
    if (gameType === "favourites") {
      setResponseMsg(favoriteGamesMsg);
    } else if (gameType === "playlist") {
      setResponseMsg(watchlistGamesMsg);
    } else if (gameType.includes("subject")) {
      setResponseMsg(categoryGamesMessage);
    } else {
      // In case of search page lazy loading
    }
  }, [watchlistGamesMsg, favoriteGamesMsg, categoryGamesMessage]);

  const getHeader = () => {
    if (gameType === "favourites") {
      return "Favourites";
    } else if (gameType === "playlist") {
      return "Play List";
    } else if (gameType.includes("subject")) {
      return resultSubjectName;
    } else {
      return "Search Data";
    }
  };

  return (
    <div className="games-page-container">
      <div className="games-page-header">{getHeader()}</div>
      {isApiLoading && gamesData?.length === 0 && (
        <MainLoader
          key={"gamePageLoader"}
          isLoading={true}
          isFullPage={false}
        />
      )}
      {gamesData?.length ? (
        <>
          {!gameType.includes("search") ? (
            <InfiniteScroll
              dataLength={gamesData?.length || 0}
              next={fetchMoreGames}
              hasMore={responseMsg !== "Data not found"}
              loader={
                <MainLoader
                  key={"gamePageGamesListLoader"}
                  isLoading={true}
                  isFullPage={false}
                />
              }
            >
              <div className="games-page-img-container">
                {gamesData?.map((img, idx) => (
                  <div key={idx} className="games-page-img-view"> 
                    <GameView image={img} />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="games-page-img-container">
              {/* <div>Showing Results for </div> */}
              {gamesData?.map((img, idx) => (
                <div key={idx} className="games-page-img-view">
                  <h2>v111</h2>
                  <GameView image={img} />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        !isApiLoading && (
          <div className="games-page-no-data">No Data Available</div>
        )
      )}
    </div>
  );
}
