import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HideToastMessage } from "../../redux/actions";
import "./ToastMessage.css";

export default function ToastMessage() {
  const dispatch = useDispatch();
  // const [isToastVisible, setIsToastVisible] = useState(false);
  const { isToastMessageVisible, toastMessage } = useSelector(
    (state) => state.appCommon
  );
  useEffect(() => {
    if (isToastMessageVisible) {
      setTimeout(() => {
        // setIsToastVisible(false);
        dispatch(HideToastMessage());
      }, 5000);
    }
  }, [isToastMessageVisible]);
  if (!isToastMessageVisible) return;
  return (
    <div
      className="toast-message-container">
      <div className="toast-message-text">{toastMessage}</div>
    </div>
  );
}
