import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import android from "../../assets/images/android_store.png";
import apple from "../../assets/images/apple.png";
import fb from "../../assets/icons/fb.png";
import ins from "../../assets/icons/ins.png";
import tw from "../../assets/icons/tw.png";
import yt from "../../assets/icons/yt.png";
import li from "../../assets/icons/li.png";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();
  const mySubscriptionDataId = useSelector(
    (state) => state.subscriptionStore.mySubscriptionData.id
  );
  return (
    <div className="footer-main-container">
      <div className="footer-left">
        <div className="footer-left-apps">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.magimatmobileapp&hl=en-IN" className="android-app-button button-view">
            <img className="android-app" src={android} alt="Android App" />
          </a>
          {/* <button className="apple-app-button button-view">
            <img className="apple-app" src={apple} alt="Apple App" />
          </button> */}
        </div>
        <div className="footer-links">
          <a target="_blank" href='https://www.magikmat.com/about-us/'>About Us</a> | 
          <a target="_blank" href='https://www.magikmat.com/terms-conditions/'> Terms and conditions</a> |
          <a target="_blank" href='https://www.magikmat.com/privacy-policy/'> Privacy Policy</a> |
          <a target="_blank" href='https://www.magikmat.com/contact-us/'> Contact Us </a> |
          {
              !mySubscriptionDataId &&
              <Link to="/subscribe">Subscribe</Link> 
          } 
          {/* <a href='https://www.magikmat.com/contact-us/'> Contact Us </a> */}
        </div>
        <div className="footer-register">
          A Product Of Federal Soft Systems {currentYear}.All Rights Reserved.
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-social-media">
          <a target="_blank" href="https://www.facebook.com/magikmatofficial" className="fb-btn">
            <img className="fb-img" src={fb} alt="facebook" />
          </a>
          <a target="_blank" href="https://www.instagram.com/magikmat_/" className="insta-btn" >
            <img className="insta-img" src={ins} alt="instagram" />
          </a>
          <a target="_blank" href="https://twitter.com/magik_mat1" className="twitter-btn" >
            <img className="twitter-img" src={tw} alt="twitter" />
          </a> 
          <a target="_blank" href="https://www.youtube.com/channel/UCS5oWAKGCbVyRaini14R_tw/channels" className="youtube-btn" >
            <img className="youtube-img" src={yt} alt="Youtube" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/magik-mat" className="linkedin-btn">
            <img className="linkedin-img" src={li} alt="Linked In" />
          </a>
        </div>
        {/* <div className='footer-email-subscribe'>
          <input className="footer-email-subscribe-input" placeholder="Enter Email..."></input>
          <button className="footer-email-subscribe-btn">SUBSCRIBE</button>
        </div> */}
        <div className="footer-register-mobile">
        A Product Of Federal Soft Systems {currentYear}.All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;