import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import MainView from './MainView';
import ErrorComponent from './components/errorComponent/ErrorComponent';
import HomePage from './pages/home/HomePage';
import GameDetailPage from './pages/gameDetail/GameDetailPage';
import Subscribe from './pages/subscribe/Subscribe';
import Upgrade from './pages/subscribe/Upgrade';
import Profile from './pages/profile/Profile';
import GamesPage from './pages/games/GamesPage';
import Invoice from './pages/invoice/Invoice';
import PaymentHistoryComp from './pages/paymentHistory/PaymentHistory';
import GradePage from './pages/games/GradePage';
const router  = createBrowserRouter([
  {
    element: <MainView />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/upgrade",
        element: <Upgrade />,
      },
      {
        path: "/game-detail/:gameId",
        element: <GameDetailPage />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/games/:gameType",
        element: <GamesPage />,
      },
      {
        path: "/grade/:gameType",
        element: <GradePage />,
      },
      {
        path: "/my-subscription/:orderid",
        element: <Invoice />,
      },
      {
        path: "/payment-history",
        element: <PaymentHistoryComp />,
      },
    ],
    errorElement: <ErrorComponent />,
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
