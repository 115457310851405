import axiosInstance from "../../../utils/Api";

export const AddReviewApi = async (data) =>
  await axiosInstance
    .post("game/addReview", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const RemoveReviewApi = async (data) =>
  await axiosInstance
    .delete("game/removeReview", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GetReviewApi = async (data) =>
  await axiosInstance
    .get("game/getReview", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
