import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { encodedId } from "../../../utils/Common";
import {
  CheckWishlist,
  CheckWishlistData,
  CreateWatchlistData,
  FetchError,
  FetchStart,
  FetchSuccess,
  GetWatchlistGamesData,
  RemoveWatchlistData,
  ShowToastMessage,
} from "../../actions";
import {
  CheckWishlistApi,
  CreateWatchlistApi,
  GetWatchlistGamesApi,
  RemoveWatchlistApi,
} from "../../api";
import {
  CHECK_WISHLIST,
  CREATE_WATCHLIST,
  GET_WATCHLIST_GAMES,
  REMOVE_WATCHLIST,
} from "../../constants";

function* CreateWatchlistService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(CreateWatchlistApi, data);
    if (res) {
      yield put(CreateWatchlistData(res.data));
      yield put(CheckWishlist({ game_id: encodedId(data.game_id), id: data.game_id }));
      yield put(ShowToastMessage('Game successfully added to Playlist'));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* RemoveWatchlistService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(RemoveWatchlistApi, data);
    if (res) {
      yield put(RemoveWatchlistData(res.data));
      yield put(CheckWishlist({ game_id: encodedId(data.game_id), id: data.game_id }));
      yield put(ShowToastMessage('Game successfully removed from Playlist'));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* GetWatchlistGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(GetWatchlistGamesApi, data);
    if (res) {
      yield put(GetWatchlistGamesData(res));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {
    yield put(FetchError());
  }
}

function* CheckWishlistService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(CheckWishlistApi, { game_id: data.game_id });
    if (res) {
      yield put(CheckWishlistData({ id: data.id, data: res.data }));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

export function* CreateWatchlistSaga() {
  yield takeEvery(CREATE_WATCHLIST, CreateWatchlistService);
}

export function* RemoveWatchlistSaga() {
  yield takeEvery(REMOVE_WATCHLIST, RemoveWatchlistService);
}

export function* GetWatchlistGamesSaga() {
  yield takeEvery(GET_WATCHLIST_GAMES, GetWatchlistGamesService);
}

export function* CheckWishlistSaga() {
  yield takeEvery(CHECK_WISHLIST, CheckWishlistService);
}

export default function* rootSaga() {
  yield all([
    fork(CreateWatchlistSaga),
    fork(RemoveWatchlistSaga),
    fork(GetWatchlistGamesSaga),
    fork(CheckWishlistSaga),
  ]);
}
