import {
    ADD_REVIEW,
    ADD_REVIEW_DATA,
    ADD_REVIEW_ERROR,
    REMOVE_REVIEW,
    REMOVE_REVIEW_DATA,
    REMOVE_REVIEW_ERROR,
    GET_REVIEW,
    GET_REVIEW_DATA,
    GET_REVIEW_ERROR,
    SET_DEFAULT_GAME_REVIEWS,
  } from "../../constants";
  
  export const AddReview = (data) => {
    return {
      type: ADD_REVIEW,
      data: data,
    };
  };
  
  export const AddReviewData = (data) => {
    return {
      type: ADD_REVIEW_DATA,
      data: data,
    };
  };
  
  export const AddReviewError = (data) => {
    return {
      type: ADD_REVIEW_ERROR,
      data: data,
    };
  };
  
  export const RemoveReview = (data) => {
    return {
      type: REMOVE_REVIEW,
      data: data,
    };
  };
  
  export const RemoveReviewData = (data) => {
    return {
      type: REMOVE_REVIEW_DATA,
      data: data,
    };
  };
  
  export const RemoveReviewError = (data) => {
    return {
      type: REMOVE_REVIEW_ERROR,
      data: data,
    };
  };
  
  export const GetReview = (data) => {
    return {
      type: GET_REVIEW,
      data: data,
    };
  };
  
  export const GetReviewData = (data) => {
    return {
      type: GET_REVIEW_DATA,
      data: data,
    };
  };
  
  export const GetReviewError = (data) => {
    return {
      type: GET_REVIEW_ERROR,
      data: data,
    };
  };
  
  export const SetDefaultGameReviews = () => {
    return {
      type: SET_DEFAULT_GAME_REVIEWS
    };
  };
  