import {
  GAME_BANNERS_DATA,
  GAME_DETAILS_DATA,
  GET_CATEGORY_GAMES_DATA,
  GET_RECOMMENDED_GAMES_DATA,
  HOME_PAGE_GAMES_DATA,
  PLAY_GAME_DATA,
  SEARCH_GAMES_DATA,
  SET_DEFAULT_BANNERS_GAMES,
  SET_DEFAULT_CATEGORY_GAMES,
  SET_DEFAULT_HOME_PAGE_GAMES,
  SET_DEFAULT_PLAY_GAME_DATA,
  GET_GRADE_GAMES
} from "../../constants";

const INIT_STATE = {
  gameDetailsData: {},
  categoryGamesData: [],
  categoryGamesMessage: "",
  gameBannersData: [],
  homePageGamesData: [],
  homePageGamesMessage: "",
  recommendedGamesData: [],
  searchGamesData: [],
  playGameData: {},
  gradeGamesData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GAME_DETAILS_DATA: {
      return {
        ...state,
        gameDetailsData: action.data[0],
      };
    }
    case GET_CATEGORY_GAMES_DATA: {
      return {
        ...state,
        categoryGamesData: [...state.categoryGamesData, ...action.data.data],
        categoryGamesMessage: action.data.message
      };
    }
    case SET_DEFAULT_CATEGORY_GAMES: {
      return {
        ...state,
        categoryGamesData: [],
        categoryGamesMessage: ""
      };
    }
    case GAME_BANNERS_DATA: {
      return {
        ...state,
        gameBannersData: action.data,
      };
    }
    case SET_DEFAULT_BANNERS_GAMES: {
      return {
        ...state,
        gameBannersData: [],
      };
    }
    case HOME_PAGE_GAMES_DATA: {
      return {
        ...state,
        homePageGamesData: [...state.homePageGamesData, ...action.data.data],
        homePageGamesMessage: action.data.message
      };
    }
    case SET_DEFAULT_HOME_PAGE_GAMES: {
      return {
        ...state,
        homePageGamesData: [],
        homePageGamesMessage: ""
      };
    }
    case GET_RECOMMENDED_GAMES_DATA: {
      return {
        ...state,
        recommendedGamesData: action.data
      };
    }
    case SEARCH_GAMES_DATA: {
      return {
        ...state,
        searchGamesData: action.data,
      };
    }
    case PLAY_GAME_DATA: {
      return {
        ...state,
        playGameData: action.data,
      };
    }
    case SET_DEFAULT_PLAY_GAME_DATA: {
      return {
        ...state,
        playGameData: {},
      };
    }
    case GET_GRADE_GAMES: {
      return {
        ...state,
        gradeGamesData: action.data,
      };
    }
    
    default:
      return state;
  }
};
