import axiosInstance from "../../utils/Api";

export const UserLoginApi = async (data) =>
  await axiosInstance
    .get("user/auth", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const UserTokenCheckApi = async () =>
  await axiosInstance
    .get("user/tokencheck", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const UserProfileApi = async () =>
  await axiosInstance
    .get("user/userprofile", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const EditUserApi = async (data) =>
  await axiosInstance
    .post("user/profileUpdate", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const UserAddressApi = async () =>
  await axiosInstance
    .get("user/getUserAddress", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const EditUserAddressApi = async (data) =>
  await axiosInstance
    .post("user/updateUserAddress", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const StatesListApi = async () =>
  await axiosInstance
    .get("user/getStates", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const PincodeDataApi = async (data) =>
  await axiosInstance
    .get("user/getPincodeDetails", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,      
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const UserAuthApi = async (data) =>
  await axiosInstance
    .post("user/userAuth", data)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});