import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SubjectListData } from "../actions";
import { SubjectListApi } from "../api";
import { SUBJECT_LIST } from "../constants";

function* SubjectListService() {
  try {
    // yield put(FetchStart());
    const res = yield call(SubjectListApi);
    if (res) {
      yield put(SubjectListData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

export function* SubjectListSaga() {
  yield takeEvery(SUBJECT_LIST, SubjectListService);
}

export default function* rootSaga() {
  yield all([fork(SubjectListSaga)]);
}
