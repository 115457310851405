import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getEnvUrl } from "../../utils/Api";
import { ShowLoginModal, UserTokenCheck, UserAddress } from "../../redux/actions";
// import basicpackage from "../../assets/icons/basicpackage.png";
// import premiumpackage from "../../assets/icons/premiumpackage.png";
// import advancedpackage from "../../assets/icons/advancedpackage.png";
import "./Subscribe.css";

 

// const basicFeatureColor =
//   "linear-gradient(rgb(247 148 32 / 24%) 0%, rgb(16, 16, 16) 100%)";
// const superFeatureColor =
//   "linear-gradient(rgb(220 28 80 / 23%) 0%, rgb(16, 16, 16) 100%)";
// const PremiumFeatureColor =
//   "linear-gradient(rgb(107 203 225 / 13%) 0%, rgb(16, 16, 16) 100%)";

var timestamp = new Date().getTime();

function addGradientToColor(background_color) {
  return `linear-gradient(135deg, ${background_color}, #0D0D0E)`;
}

export default function Subscribe() {
  const dispatch = useDispatch();
  const [editAddress, setEditAddress] = useState(false);
  const { subscriptionPlanListData, mySubscriptionData } = useSelector( 
    (state) => state.subscriptionStore
  );
  console.log(subscriptionPlanListData);
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  const userProfileAddress = useSelector(
    (state) => state.userStore.userAddressData
  );
  const [subscriptionPlanList, setSubscriptionPlanList] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
      dispatch(UserAddress());
    }
  }, []);

  useEffect(() => {
    if (subscriptionPlanListData.length) {
      const modifiedSubscriptionData = subscriptionPlanListData?.map((plan) => {
        const gradientColor = addGradientToColor(plan.background_color);
        return {
          ...plan,
          icon:
          `${getEnvUrl()}admin/subscription/subscriptionicon?id=${plan.id}&hash=${timestamp}'}`,
          color:
          gradientColor ,
        };
      });
      setSubscriptionPlanList(modifiedSubscriptionData);
    }
  }, [subscriptionPlanListData]);
  const handlePayment = (packageDetail) => {
    if (!userProfileMobile) {
      dispatch(ShowLoginModal());
    } else if(!userProfileAddress) {
        setEditAddress(true);
    } else {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", `${getEnvUrl()}payment/onlinepayment`);
      let plan_id = document.createElement("input");
      plan_id.setAttribute("name", "plan_id");
      plan_id.setAttribute("value", packageDetail.id);

      let token = document.createElement("input");
      token.setAttribute("name", "token");
      token.setAttribute("value", localStorage.getItem("token")?.split(" ")[1]);
      form.appendChild(plan_id);
      form.appendChild(token);
      document.getElementsByTagName("body")[0].appendChild(form).submit();
    }
  };
  const dropdownRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        setEditAddress(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="subscribe-container">
      {userProfileMobile && mySubscriptionData?.plan_id && (
        <div className="current-plan-container">
          <div className="current-plan-title">Your Current Plan :</div>
          <div className="current-plan-data">
            {mySubscriptionData.subscriptionplans.name}
          </div>
        </div>
      )}
      <div className="subscribe-plan-title">Choose a plan</div>
      {/* this toggle for next versions */}
      {/* <div className="subscribe-billed-container">
          <p className="subscribe-billed-monthly">Billed monthly</p>
          <Slider />
          <p className="subscribe-billed-annualy">Billed annualy</p>
        </div> */}
      <div className="subscribe-packages-container">
        {subscriptionPlanList?.map((packageDetail) => (
          <div
            key={packageDetail.id}
            className={
              packageDetail.name === "premium"
                ? "subscribe-package-detail-container"
                : "subscribe-package-detail-container adjust-box-width"
            }
            style={{ background: packageDetail.color }}
          >
            <div className="package-detail-title">
              <img
                src={packageDetail.icon}
                className={packageDetail.offer ? "package-offer-img" : ""}
                alt="Plan Icon" style={{maxWidth:"80px"}}
              />
              <span className="package-title">{packageDetail.name}</span>
            </div>
            <div className="package-detail-price">
              {" "}
              <div>
                {packageDetail.offer ? (
                  <div className="package-detail-offer-price">
                    ₹{packageDetail.discount_price}
                    <span>₹{packageDetail.price}</span>
                  </div>
                ) : (
                  `₹${packageDetail.price}`
                )}
              </div>{" "}
              <span
                className={
                  packageDetail.offer
                    ? "package-detail-price-div offer-div"
                    : "package-detail-price-div"
                }
              >
                /
              </span>{" "}
              {packageDetail.tenure} months 
            </div>
            <ul className="package-detail-description-container">
              {packageDetail.subscriptionfeatures.map((desc, idx) => (
                <li key={desc + idx}>{desc.feature}</li>
              ))}
            </ul>
            <button
              className="package-purchase-button"
              onClick={() => handlePayment(packageDetail)}
              disabled
            >
              Purchase
            </button>
          </div>
        ))}
      </div>
      {editAddress && (
        <div ref={dropdownRef}>
        <div className="game-details-notaccessable-popup">
          <div className="game-notaccessable-popup-view">
            <div className="game-access-message">
              Please add address in <Link to={"/profile"}>edit profile</Link> page to purchase this subscription. 
            </div>
          </div>
        </div>
        </div>
      )}
      {/* for next versions */}
      {/* <div className="subscribe-features-container">
        <div className="subscribe-features-title"> Features </div>
        <div className="subscribe-features-description">
          Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting
          Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
          Since The 1500S
        </div>
        <div className="subscribe-features-description">
          Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting
          Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text
        </div>
        <div className="subscribe-features-description">
          Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting
          Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
          Since The 1500S. Lorem Ipsum Is Simply Dummy Text Of The Printing And
          Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard
          Dummy Text Ever Since The 1500S
        </div>
      </div> */}
      {/* <button className="subscribe-cancel-anytime" disabled>
        You're Free To Cancel Anytime After This First Month
      </button> */}
    </div>
  );
}
