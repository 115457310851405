import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  OnlinePaymentData,
  PaymentHistoryData,
  PaymentReceiptData,
  PaymentResponseData,
} from "../actions";
import {
  OnlinePaymentApi,
  PaymentHistoryApi,
  PaymentReceiptApi,
  PaymentResponseApi,
} from "../api";
import {
  ONLINE_PAYMENT,
  PAYMENT_HISTORY,
  PAYMENT_RECEIPT,
  PAYMENT_RESPONSE,
} from "../constants";

function* OnlinePaymentService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(OnlinePaymentApi, data);
    if (res) {
      yield put(OnlinePaymentData(res));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* PaymentResponseService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(PaymentResponseApi, data);
    if (res) {
      yield put(PaymentResponseData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* PaymentHistoryService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(PaymentHistoryApi, data);
    if (res) {
      yield put(PaymentHistoryData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* PaymentReceiptService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(PaymentReceiptApi, data);
    if (res) {
      yield put(PaymentReceiptData({...res.data[0], gstData: res.gstdata}));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

export function* OnlinepaymentSaga() {
  yield takeEvery(ONLINE_PAYMENT, OnlinePaymentService);
}

export function* PaymentResponseSaga() {
  yield takeEvery(PAYMENT_RESPONSE, PaymentResponseService);
}

export function* PaymentHistorySaga() {
  yield takeEvery(PAYMENT_HISTORY, PaymentHistoryService);
}

export function* PaymentReceiptSaga() {
  yield takeEvery(PAYMENT_RECEIPT, PaymentReceiptService);
}

export default function* rootSaga() {
  yield all([
    fork(OnlinepaymentSaga),
    fork(PaymentResponseSaga),
    fork(PaymentHistorySaga),
    fork(PaymentReceiptSaga),
  ]);
}
