import React, { useEffect } from "react";
import "./MainLoader.css";

export default function MainLoader({ isLoading=false, isFullPage=true }) {
  useEffect(() => {
    if (isFullPage && isLoading) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [isFullPage, isLoading]);
  if (!isLoading) return;
  return (
    <div className={isFullPage ? "main-loader-container" : "dots-loader-container"}>
      <div className="dots-loader"></div>
    </div>
  );
}
