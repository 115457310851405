import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Carousel from "react-multi-carousel";
import {
  GameBanners,
  HomePageGames,
  SetDefaultBannerGames,
  SetDefaultHomePageGames,
  UserTokenCheck,
} from "../../redux/actions";
import ImageLoader from "../../components/imageLoader/ImageLoader";
import GameListCorousal from "../../components/gameListCorousal/GameListCorousal";
import GradeCorousal from "../../components/gameListCorousal/GradeCorousal";

import { gameBannersModify, homepageGamesModify } from "../../utils/Common";
import MainLoader from "../../components/mainLoader/MainLoader";
import BannerGameImages from "./BannerGameImages";
import "react-multi-carousel/lib/styles.css";
import "./HomePage.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1023, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const basicImgData = {
  width: "100vw",
  height: "calc(100vw * 0.28)",
};
function HomePage() {
  const dispatch = useDispatch();
  const [bannerImages, setBannerImages] = useState([]);
  const [homePageGamesList, setHomePageGamesList] = useState([]);
  const [gameListCount, setGameListCount] = useState(1);
  const { gameBannersData, homePageGamesData, homePageGamesMessage } =
    useSelector((state) => state.gameStore.gameData);
  const fetchMoreData = () => {
    dispatch(HomePageGames({ hit: gameListCount }));
    setGameListCount(gameListCount + 1);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    dispatch(SetDefaultBannerGames());
    dispatch(SetDefaultHomePageGames());
    dispatch(GameBanners({ type: "WEB" }));
    fetchMoreData();
  }, []);
  useEffect(() => {
    setBannerImages(gameBannersModify(gameBannersData));
  }, [gameBannersData]);
  useEffect(() => {
    const modifiedGamesData = homePageGamesData?.map((gameData) => {
      return {
        ...gameData,
        data: homepageGamesModify(gameData.data,gameData.view),
      };
    });
    setHomePageGamesList(homepageGamesModify(modifiedGamesData));
  }, [homePageGamesData]);

  return (
    <div className="home-page-container">
      {bannerImages.length ? (
        <Carousel
          responsive={responsive}
          showDots={true}
          infinite={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          slidesToSlide={1}
          containerClass="main-corousal-container"
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={1000}
        >
          {bannerImages.map((img, idx) => (
            <div key={idx}>
              <BannerGameImages img={img} />
            </div>
          ))}
        </Carousel>
      ) : (
        <ImageLoader {...basicImgData} />
      )}
      <div className="home-page-data-container">
        <InfiniteScroll
          dataLength={homePageGamesList?.length || 0}
          next={fetchMoreData}
          hasMore={homePageGamesMessage !== "Data not found"}
          loader={
            <MainLoader
              key={"homePageGameList"}
              isLoading={true}
              isFullPage={false}
            />
          }
        >
          {homePageGamesList?.map((data, idx) => (
            <>
             {data.catagory!='Grade' && <GameListCorousal
              key={idx}
              title={data.catagory}
              imagesData={data.data}
              viewtype={data.view}
            />}
            {data.catagory=='Grade' && <GradeCorousal
              key={idx}
              title={data.catagory}
              imagesData={data.data}
              viewtype={data.view}
            />}
            </>
           

            
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default HomePage;
