import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactToPrint from "react-to-print";
import "./Invoice.css";
import { PaymentReceipt, UserTokenCheck } from "../../redux/actions";
import { dateFormat } from "../../utils/Common";

export default function Invoice() {
  const dispatch = useDispatch();
  const location = useLocation();
  const InvoiceRef = useRef();
  const [address, setAddress] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [taxDetails, setTaxDetails] = useState([]);
  const paymentReceiptData = useSelector(
    (state) => state.paymentStore.paymentReceiptData
  );
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    const orderid = new URLSearchParams(location.search).get("orderid");
    dispatch(PaymentReceipt({ orderid: orderid }));
  }, []);

  useEffect(() => {
    if (paymentReceiptData) {
      const gstaddress = paymentReceiptData?.gstData
        ? paymentReceiptData?.gstData[1]?.value
        : "";
      const gstnumber = paymentReceiptData?.gstData
        ? paymentReceiptData?.gstData[0]?.value
        : "";
      const taxbreakup = paymentReceiptData?.taxbreakup ? JSON.parse(paymentReceiptData?.taxbreakup) : [];
      setAddress(gstaddress);
      setGstNumber(gstnumber);
      setTaxDetails(taxbreakup);
    }
  }, [paymentReceiptData]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="invoice-button">Print Invoice</button>
        )}
        content={() => InvoiceRef?.current}
        documentTitle="Invoice"
      />
      <div className="invoice-container" ref={InvoiceRef}>
        <header>
          <h1>Invoice</h1>
          <address>
            <p className="invoiceAddress">{address}.</p>
            <p>GSTIN {gstNumber}</p>
          </address>
        </header>
        <article>
          <h1>Recipient</h1>
          <address style={{ fontStyle: "normal" }}>
            <p>
              {paymentReceiptData?.useraccount?.display_name
                ? paymentReceiptData?.useraccount?.display_name
                : ""}
            </p>
          </address>
          <table className="meta">
            <tbody>
              <tr>
                <th>
                  <span>Order Id</span>
                </th>
                <td>
                  <span>{paymentReceiptData?.orderid}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>Reference Id</span>
                </th>
                <td>
                  <span>{paymentReceiptData?.payment_referenceid}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>Date</span>
                </th>
                <td>
                  <span>{dateFormat(paymentReceiptData?.txtime)}</span>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentReceiptData?.payment_type === "PACKAGE" ||  paymentReceiptData?.payment_type === "PACKAGE_UPGRADE" ? (
            <table className="inventory">
              <thead>
                <tr>
                  <th>
                    <span>Plan</span>
                  </th>
                  <th>
                    <span>Tenure</span>
                  </th>
                  <th>
                    <span>Start Date</span>
                  </th>
                  <th>
                    <span>End Date</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>
                      {paymentReceiptData?.subscriptionplans?.plan_name}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.subscriptionplans?.tenure} Months
                    </span>
                  </td>
                  <td>
                    <span>                      
                      {paymentReceiptData?.subscribeusers[0]?.start_date
                        ? dateFormat(
                            paymentReceiptData?.subscribeusers[0]?.start_date
                          )
                        : "------"}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.subscribeusers[0]?.end_date
                        ? dateFormat(
                            paymentReceiptData?.subscribeusers[0]?.end_date
                          )
                        : "------"}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.paymentstatuses?.payment_status}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className="inventory">
              <thead>
                <tr>
                  <th>
                    <span>Game Id</span>
                  </th>
                  <th>
                    <span>Game Name</span>
                  </th>
                  <th>
                    <span>Game Description</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>
                      {paymentReceiptData?.purchase_details &&
                        JSON.parse(paymentReceiptData?.purchase_details)?.id}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.purchase_details &&
                        JSON.parse(paymentReceiptData?.purchase_details)?.name}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.purchase_details &&
                        JSON.parse(paymentReceiptData?.purchase_details)
                          ?.description}
                    </span>
                  </td>
                  <td>
                    <span>
                      {paymentReceiptData?.paymentstatuses?.payment_status}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {/* <table className="inventory">
            <thead>
              <tr>
                <th>
                  <span>Plan</span>
                </th>
                <th>
                  <span>Tenure</span>
                </th>
                <th>
                  <span>Start Date</span>
                </th>
                <th>
                  <span>End Date</span>
                </th>
                <th>
                  <span>Status</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>
                    {paymentReceiptData?.subscriptionplans?.plan_name}
                  </span>
                </td>
                <td>
                  <span>{paymentReceiptData?.subscriptionplans?.tenure} Months</span>
                </td>
                <td>
                  <span>
                    {paymentReceiptData?.subscribeusers
                      ? dateFormat(
                          paymentReceiptData?.subscribeusers[0]?.start_date
                        )
                      : ""}
                  </span>
                </td>
                <td>
                  <span>
                    {paymentReceiptData?.subscribeusers
                      ? dateFormat(
                          paymentReceiptData?.subscribeusers[0]?.end_date
                        )
                      : ""}
                  </span>
                </td>
                <td>
                  <span>
                    {paymentReceiptData?.paymentstatuses?.payment_status}
                  </span>
                </td>
              </tr>
            </tbody>
          </table> */}
          <table className="balance">
            <tbody>
              <tr>
                <th>
                  <span>Amount</span>
                </th>
                <td>
                  <span data-prefix>₹</span>
                  <span>
                    {Number.parseFloat(paymentReceiptData?.amount)?.toFixed(2)}
                  </span>
                </td>
              </tr>
              {taxDetails.map((tax) => (
                <tr>
                  <th>
                    <span>{tax.tax_name}</span>
                  </th>
                  <td>
                    <span data-prefix>₹</span>
                    <span>
                      {Number.parseFloat(tax?.taxvalue)?.toFixed(2)}
                    </span>
                  </td>
                </tr>
              ))}
              <tr>
                <th>
                  <span>Total</span>
                </th>
                <td>
                  <span data-prefix>₹</span>
                  <span>
                    {Number.parseFloat(
                      paymentReceiptData?.total_amount
                    )?.toFixed(2)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
        {/* <aside>
          <h1>
            <span>Additional Notes</span>
          </h1>
          <div>
            <p>
              A finance charge of 1.5% will be made on unpaid balances after 30
              days.
            </p>
          </div>
        </aside> */}
      </div>
    </>
  );
}
