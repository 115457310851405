import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_lOGIN_MODAL,
  HIDE_TOAST_MESSAGE,
  SHOW_lOGIN_MODAL,
  SHOW_TOAST_MESSAGE,
} from "../constants";

export const FetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const FetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const FetchError = () => {
  return {
    type: FETCH_ERROR,
  };
};

export const ShowLoginModal = () => {
  return {
    type: SHOW_lOGIN_MODAL,
  };
};

export const HideLoginModal = () => {
  return {
    type: HIDE_lOGIN_MODAL,
  };
};

export const ShowToastMessage = (data) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    data: data
  };
};

export const HideToastMessage = (data) => {
  return {
    type: HIDE_TOAST_MESSAGE
  };
};
