import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEnvUrl } from "../../utils/Api";
import profilepic from "../../assets/icons/profilepic.png";
// import basicpackage from "../../assets/icons/basicpackage.png";
// import premiumpackage from "../../assets/icons/premiumpackage.png";
// import advancedpackage from "../../assets/icons/advancedpackage.png";
import "./Profile.css";
import {
  daysDifference,
  shortMonths,
  validateEmail,
  validateUserName,
  getDateFullMonthFormat,
} from "../../utils/Common";
import { useState } from "react";
import {
  EditUser,
  EditUserAddress,
  PincodeData,
  SetDefaultPincodeData,
  UserAddress,
} from "../../redux/actions";

// const backgroundColors = {
//   1: "linear-gradient(rgb(247 148 32 / 24%) 0%, rgb(16, 16, 16) 100%)",
//   2: "linear-gradient(rgb(220 28 80 / 23%) 0%, rgb(16, 16, 16) 100%)",
//   3: "linear-gradient(rgb(107 203 225 / 13%) 0%, rgb(16, 16, 16) 100%)",
// };

function addGradientToColor(background_color) {
  return `linear-gradient(135deg, ${background_color}, #0D0D0E)`;
}

var timestamp = new Date().getTime();

export default function Userprofile() {
  const initialAddress = {
    pincode: "",
    state_id: "",
    city: "",
    address: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [editUserName, setEditUserName] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [isError, setIsError] = useState("");
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isAddressError, setIsAddressError] = useState("");
  const [userAddress, setuserAddress] = useState(initialAddress);
  const { display_name, mobile_number, email, country_code } = useSelector(
    (state) => state.userStore.userProfileData
  );
  const { statesList, pincodeData, userAddressData } = useSelector(
    (state) => state.userStore
  );
  const { subscriptionPlanListData, mySubscriptionData } = useSelector(
    (state) => state.subscriptionStore
  );
  console.log(subscriptionPlanListData);
  const getSubscriptionPlanData = subscriptionPlanListData?.filter(
    (plan) => mySubscriptionData.plan_id === plan.id
  )[0];
  
 
  useEffect(() => {
    dispatch(UserAddress());
  }, []);
  useEffect(() => {
    setEditUserName(display_name);
    setEditUserEmail(email);
  }, [display_name, email]);
  useEffect(() => {
    if (pincodeData) {
      setuserAddress({
        ...userAddress,
        state_id: pincodeData?.state_id ? pincodeData?.state_id : "",
        city: pincodeData?.cityname ? pincodeData?.cityname[0] : "",
      });
    }
  }, [pincodeData]);
  useEffect(() => {
    if(userAddressData?.pincode) {
      dispatch(PincodeData({ pincode: userAddressData?.pincode }));
      setuserAddress({
        ...userAddress,
        pincode: userAddressData?.pincode ? userAddressData?.pincode : "",
        state_id: userAddressData?.state_id ? userAddressData?.state_id : "",
        city: userAddressData?.city ? userAddressData?.city : "",
        address: userAddressData?.address ? userAddressData?.address : "",
      });
    }
  }, [userAddressData])
  

  const getModifiedDate = (date) => {
    let objectDate = new Date(date);
    let day = objectDate.getDate();
    let month = objectDate.getMonth() + 1;
    let year = objectDate.getFullYear();
    return `${shortMonths[month]} ${day}, ${year}`;
  };
  const handleEditUser = () => {
    if (!validateUserName(editUserName)) {
      setIsError("Enter valid name");
      return;
    } else if (!validateEmail(editUserEmail)) {
      setIsError("Enter valid e-mail");
      return;
    }
    const editedUser = {
      name: editUserName,
      email: editUserEmail,
    };
    dispatch(EditUser(editedUser));
    setIsEditVisible(false);
  };

  const editUserProfile =  ()=>{
   setEditUserName(display_name);
   setEditUserEmail(email);
   setIsEditVisible(true)
 }
  const getPincodeData = () => {
    if (userAddress?.pincode?.length === 6) {
      setIsAddressError("");
      dispatch(PincodeData({ pincode: userAddress.pincode }));
    } else {
      setIsAddressError("Please enter correct pincode");
    }
  };
  const handleEditAddress = () => {
    const { pincode, state_id, city, address } = userAddress;
    if (!pincode || !state_id || !city || !address) {
      setIsAddressError("Please enter all required fields.");
      return;
    }
    if (!address || address.length < 5 || address.length > 300) {
      setIsAddressError("Address must have between 5 and 300 characters.");
      return;
    } else {
      setIsAddressError(""); 
    }
    if (!pincode || !state_id || !city || !address) {
      if (!pincode) {
        setIsAddressError("Please enter pincode");
      } else if (pincode && pincode.length !== 6) {
        setIsAddressError("Please enter correct pincode");
      } else if (!state_id) {
        setIsAddressError("Please enter state");
      } else if (!city) {
        setIsAddressError("Please enter city");
      } else if (!address) {
        setIsAddressError("Please enter address");
      }
      return;
    }
    dispatch(EditUserAddress({ ...userAddress }));
    setIsEditAddress(false);
    setIsAddressError("");
    setuserAddress(initialAddress);
    dispatch(SetDefaultPincodeData());
  };
  const handleEditAddressClose = () => {
    setIsEditAddress(false);
    setIsAddressError("");
    // setuserAddress(initialAddress);
    dispatch(UserAddress());
    dispatch(SetDefaultPincodeData());
  };

  return (
    <div className="Profile-main">
      <div className="Profile-details">
        <p className="Profile-heading-info">Basic Information</p>
        {isEditVisible ? (
          <div className="Profile-img-box-1">
            <div className="Profile-box-1">
              <div className="Profile-image-and-name">Edit Profile</div>
            </div>
            <div className="input-box">
              <div className="text-1">
                <span className="Phone-number-text">Name</span>
                <input
                  className="Mobile-number-1"
                  onChange={(e) => {
                    setEditUserName(e.target.value);
                    setIsError("");
                  }}
                  value={editUserName}
                />
              </div>
              <div className="text-1">
                <span className="Phone-number-text">E-Mail</span>
                <input
                  className="Mobile-number-1"
                  onChange={(e) => {
                    setEditUserEmail(e.target.value);
                    setIsError("");
                  }}
                  value={editUserEmail}
                />
              </div>
            </div>
            {isError && <div className="edit-user-error">{isError}</div>}
            <div className="btn-menu">
              <button className="submit-btn" onClick={() => handleEditUser()}>
                Submit
              </button>
              <button
                className="close-btn"
                onClick={() => setIsEditVisible(false)}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="Profile-img-box">
            <div className="Profile-box">
              <div className="Profile-image-and-name">
                <img
                  src={profilepic}
                  alt="profile img"
                  className="Profile-image"
                />
                <div className="profile-edit-box">
                  <div className="Profile-name">{display_name}</div>
                  <div className="Profile-email">{email}</div>
                </div>
              </div>
              <button
                className="Profile-edit"
                onClick={() => editUserProfile()}
              >
                Edit profile
              </button>
            </div>
            <div className="Profile-phonenumber">
              <span className="Phone-number-text">Phone Number |</span>
              <p className="Mobile-number">
                +{country_code} {mobile_number}
              </p>
            </div>
          </div>
        )}
        {isEditAddress ? (
          <div className="Profile-img-box-1">
            <div className="Profile-box-1">
              <div className="Profile-image-and-name">Edit Address</div>
            </div>
            <div className="input-box">
              <div className="text-1">
                <span className="Phone-number-text">Pincode</span>
                <input
                  className="Mobile-number-1"
                  onChange={(e) => {
                    setuserAddress({ ...userAddress, pincode: e.target.value });
                  }}
                  value={userAddress.pincode}
                  onBlur={() => getPincodeData()}
                />
              </div>
              <div className="text-1">
                <span className="Phone-number-text">State</span>
                <select
                  className="Mobile-number-1 stateListView"
                  value={userAddress.state_id}
                  onChange={(e) =>
                    setuserAddress({ ...userAddress, state_id: e.target.value })
                  }
                  disabled={!userAddress.state_id}
                >
                  {userAddress.state_id ? (
                    statesList?.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.state_name}
                      </option>
                    ))
                  ) : (
                    <option value={""}></option>
                  )}
                </select>
              </div>
              {/* <div className="text-1">
                <span className="Phone-number-text">District</span>
                <input
                  className="Mobile-number-1"
                  onChange={(e) => {
                    setDistrict(e.target.value);
                  }}
                  value={district}
                />
              </div> */}
              <div className="text-1">
                <span className="Phone-number-text">City</span>
                <select
                  className="Mobile-number-1 stateListView"
                  value={userAddress.city}
                  onChange={(e) =>
                    setuserAddress({ ...userAddress, city: e.target.value })
                  }
                  disabled={!userAddress.state_id}
                >
                  {pincodeData?.cityname?.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-1">
                <span className="Phone-number-text">Address</span>
                <input
                  className="Mobile-number-1"
                  value={userAddress.address}
                  onChange={(e) => {
                    setuserAddress({ ...userAddress, address: e.target.value });
                  }}
                  disabled={!userAddress.state_id}
                />
              </div>
            </div>
            {isAddressError && (
              <div className="edit-user-error">{isAddressError}</div>
            )}
            <div className="btn-menu">
              <button
                className="submit-btn"
                onClick={() => handleEditAddress()}
              >
                Submit
              </button>
              <button
                className="close-btn"
                onClick={() => handleEditAddressClose()}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="Profile-img-box">
            <div className="Profile-box">
              <div className="Profile-image-and-name">
                <div className="profile-edit-box">
                  <div className="addressTitle">Contact Address</div>
                </div>
              </div>
              <button
                className="Profile-edit"
                onClick={() => setIsEditAddress(true)}
              >
                Edit Address
              </button>
            </div>
            <div className="Profile-phonenumber">
              <span className="Phone-number-text">Address |</span>
              <p className="Mobile-number">
                {userAddressData
                  ? userAddressData?.address +
                    ", " +
                    userAddressData?.city +
                    ", " +
                    statesList.find(state => state.id === userAddressData?.state_id)?.state_name +
                    " - " +
                    userAddressData?.pincode
                  : ""}
              </p>
            </div>
          </div>
        )}
      </div>
      {mySubscriptionData?.plan_id && (
        <div className="Profile-upgrade">
          <div className="Profile-info">
            <p className="Profile-heading-info">My Subscriptions</p>
            {daysDifference(mySubscriptionData.subscription_end_date) > 0 && (
              <p className="Profile-heading-info-last">
                Current Plan Will Expire On{" "}
                {getDateFullMonthFormat(
                  mySubscriptionData.subscription_end_date
                )}
              </p>
            )}
          </div>
          <div
            className="Profile-second-box"
            style={{
              background: addGradientToColor(getSubscriptionPlanData.background_color),
            }}
          >
            <div className="Profile-advance">
              <div className="Profile-advance-image">
                <img
                  src={`${getEnvUrl()}admin/subscription/subscriptionicon?id=${getSubscriptionPlanData.id}&hash=${timestamp}'}`
                  }
                  alt="Plan Icon"
                  className="profile-subscription-icon"
                />
                <span className="Profile-advanced-text">
                  {getSubscriptionPlanData?.name}
                </span>
              </div>
              {/* {getSubscriptionPlanData?.id !== 3 && (
                <Link to="/subscribe" className="Profile-upgrade-btn">
                  Upgrade
                </Link>
              )} */}
              {/* {getSubscriptionPlanData?.id && (
                <Link
                  to="/my-subscription/invoice/orderid:3132920260"
                  className="Profile-upgrade-btn"
                >
                  Invoice
                </Link>
              )} */}
            </div>
            <div>
              <div className="Profile-list">
                <div className="Profile-list-point-data">
                  <span className="Profile-list-point"></span>₹
                  {getSubscriptionPlanData?.price} /{" "}
                  {getSubscriptionPlanData?.tenure} months
                </div>
                <div className="Profile-list-point-data">
                  <span className="Profile-list-point"></span>
                  Subscribed Since{" "}
                  {getModifiedDate(mySubscriptionData.subscription_start_date)}
                </div>
                {getSubscriptionPlanData?.subscriptionfeatures?.map((data) => (
                  <div key={data.id} className="Profile-list-point-data">
                    <span className="Profile-list-point"></span>
                    {data.feature}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <button
                className="Profile-Payment"
                onClick={() => navigate("/payment-history")}
              >
                Payment History
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
