import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_lOGIN_MODAL,
  HIDE_TOAST_MESSAGE,
  SHOW_lOGIN_MODAL,
  SHOW_TOAST_MESSAGE,
} from "../constants";

const INIT_STATE = {
  loading: false,
  isLoginModalVisible: false,
  isToastMessageVisible: false,
  toastMessage: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SHOW_lOGIN_MODAL: {
      return {
        ...state,
        isLoginModalVisible: true,
      };
    }
    case HIDE_lOGIN_MODAL: {
      return {
        ...state,
        isLoginModalVisible: false,
      };
    }
    case SHOW_TOAST_MESSAGE: {
      return {
        ...state,
        isToastMessageVisible: true,
        toastMessage: action.data
      };
    }
    case HIDE_TOAST_MESSAGE: {
      return {
        ...state,
        isToastMessageVisible: false,
        toastMessage: ""
      };
    }

    default:
      return state;
  }
};
