import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { AddReviewData, GetReview, GetReviewData, RemoveReviewData, SetDefaultGameReviews } from "../../actions";
import { AddReviewApi, GetReviewApi, RemoveReviewApi } from "../../api";
import { ADD_REVIEW, GET_REVIEW, REMOVE_REVIEW } from "../../constants";

function* AddReviewService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(AddReviewApi, data);
    if (res) {
      yield put(AddReviewData(res));
      yield put(SetDefaultGameReviews());
      yield put(GetReview({ game_id: data.game_id, hit: 1 }));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* RemoveReviewService({ data }) {
  try {
    // yield put(FetchStart());
    const removeReviewReq = {review_id: data.review_id}
    const res = yield call(RemoveReviewApi, removeReviewReq);
    if (res) {
      yield put(RemoveReviewData(res.data));
      yield put(SetDefaultGameReviews());
      yield put(GetReview({ game_id: data.game_id, hit: 1 }));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* GetReviewService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(GetReviewApi, data);
    if (res) {
      yield put(GetReviewData(res));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

export function* AddReviewSaga() {
  yield takeEvery(ADD_REVIEW, AddReviewService);
}

export function* RemoveReviewSaga() {
  yield takeEvery(REMOVE_REVIEW, RemoveReviewService);
}

export function* GetReviewSaga() {
  yield takeEvery(GET_REVIEW, GetReviewService);
}

export default function* rootSaga() {
  yield all([fork(AddReviewSaga), fork(RemoveReviewSaga), fork(GetReviewSaga)]);
}
