import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { PaymentHistory, UserTokenCheck } from "../../redux/actions";
import { dateFormat } from "../../utils/Common";
import "./PaymentHistory.css";

export default function PaymentHistoryComp({ handlePayment }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymenthistory = useSelector(
    (state) => state.paymentStore.paymentHistoryData
  );
  const userProfileMobile = useSelector(
    (state) => state.userStore.userProfileData?.mobile_number
  );
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    if (!userProfileMobile && !localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    dispatch(PaymentHistory());
  }, []);
  const PrintableTable = useRef();
let datedif=(d1)=>{
  const date1 = new Date(d1.start_date);
const date2 = new Date(d1.end_date);
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
 return diffDays+' Days'; 
}
  return (
    <div className="Payment-main">
      <div className="Payment-header">
        <div>
          <button
            className="Paymemt-subhead"
            onClick={() => navigate("/profile")}
          >
            Profile
          </button>
          <span>|</span>
          <a className="Payment-history">Payment History</a>
        </div>
        {paymenthistory.length && (
          <ReactToPrint
            trigger={() => (
              <button className="Payment-print">Print History</button>
            )}
            content={() => PrintableTable?.current}
            documentTitle="Payments History"
            bodyClass="printable-table"
          />
        )}
      </div>
      <center>
        <div className="scroll">
          {paymenthistory.length ? (
            <table
              ref={PrintableTable}
              id="printTable"
              className="Payment-Table"
            >
              <thead>
                <tr>
                  <td className="payment-table-align">Payment Id</td>
                  <td>Name</td>
                  <td>Tenure</td>
                  <td className="payment-table-align">Payment Date</td>
                  <td>Order ID</td>
                  <td>Status</td>
                  <td>Start Date</td>
                  <td>End Date</td>
                </tr>
              </thead>
              <tbody>
                {paymenthistory?.map((value, key) => {
                  return (
                    <tr key={key}>
                      <td>{value.paymentId}</td>
                      <td className="payment-table-align">
                        {value.subscriptionplans?.planName
                          ? value.subscriptionplans?.planName
                          : value.purchase_details
                          ? JSON.parse(value.purchase_details)?.name
                          : ""}
                      </td>
                      <td>
                        {value?.paymentstatuses?.status === "SUCCESS" 
                        && (value?.payment_type === "PACKAGE"  || value?.payment_type === "PACKAGE_UPGRADE")
                        ?value.subscriptionplans?.tenure+' Months':value?.paymentstatuses?.status === "SUCCESS" 
                        && value?.payment_type === "GAME"?
                        datedif(value?.userSubscribeGames[0])
                        :'-'
                        
                        /* {value.subscriptionplans?.tenure
                          ? value.subscriptionplans?.tenure
                          : "-"} */}
                      </td>
                      <td className="Payment-Date">
                        {dateFormat(value.payment_date)}
                      </td>
                      <td className="Type"><Link className="subsctiptionId" to={`/my-subscription/invoice?orderid=${value.orderid}`}>{value.orderid}</Link></td>
                      <td
                        className={
                          value?.paymentstatuses?.status === "SUCCESS"
                            ? "Payment-Status-success"
                            : "Payment-Status-failed"
                        }
                      >
                        {value?.paymentstatuses?.status === "SUCCESS"
                          ? "Success"
                          : value?.paymentstatuses?.status === "REFUND" ? "Refund" :value?.paymentstatuses?.status === "CANCELLED" ? "Cancelled" : "Failed"}
                      </td>
                      {/* <td className="Payment-Date">
                        {value?.subscribeusers[0]?.start_date
                          ? dateFormat(value?.subscribeusers[0]?.start_date)
                          : "-"}
                      </td>
                      <td className="Payment-Date">
                        {value?.subscribeusers[0]?.end_date
                          ? dateFormat(value?.subscribeusers[0]?.end_date)
                          : "-"}
                      </td> */}
                      <td className="Payment-Date">
                        {value?.paymentstatuses?.status==="SUCCESS"?(value?.payment_type === "PACKAGE" || value?.payment_type === "PACKAGE_UPGRADE") ? dateFormat(value?.subscribeusers[0]?.start_date) :  
                          dateFormat(value?.userSubscribeGames[0]?.start_date):'------'
                        }
                        
                      </td>
                      <td className="Payment-Date">
                        {value?.paymentstatuses?.status==="SUCCESS"?(value?.payment_type === "PACKAGE" || value?.payment_type === "PACKAGE_UPGRADE" ) ? dateFormat(value?.subscribeusers[0]?.end_date) :  
                          dateFormat(value?.userSubscribeGames[0]?.end_date):'------'
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No data available</div>
          )}
        </div>
      </center>
    </div>
  );
}
