import {
  ADD_REVIEW_DATA,
  GET_REVIEW_DATA,
  REMOVE_REVIEW_DATA,
  SET_DEFAULT_GAME_REVIEWS,
} from "../../constants";

const INIT_STATE = {
  addReviewData: {},
  removeReviewData: {},
  getReviewData: [],
  getReviewMsg: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_REVIEW_DATA: {
      return {
        ...state,
        addReviewData: action.data,
      };
    }
    case REMOVE_REVIEW_DATA: {
      return {
        ...state,
        removeReviewData: action.data,
      };
    }
    case GET_REVIEW_DATA: {
      return {
        ...state,
        getReviewData: [ ...state.getReviewData, ...action.data.data],
        getReviewMsg: action.data.message,
      };
    }
    case SET_DEFAULT_GAME_REVIEWS: {
      return {
        ...state,
        getReviewData: [],
        getReviewMsg: "",
      };
    }

    default:
      return state;
  }
};
