import axios from "axios";

export const urlPath = window.location.host;

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
axiosInstance.interceptors.response.use(
  (response) => {
    if(!response.data?.status && response.data?.errorMessage === "Session expired"){
      async function postData(url = "", data = {}) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        return response.json();
      };      
      postData(`${getEnvUrl}api/user/logout`, { deviceID: localStorage.getItem("deviceID") }).then((data) => {
        localStorage.removeItem("token");
        window.location.reload();
      });
    }
    return response;
  },
  (error) => {
    const status = error.status;
    if (status === 401) {
      //dispatch action logout
      if (JSON.parse(localStorage.getItem("token"))) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export const getEnvUrl = () => {
  if (urlPath === "uat.magikmat.net") {
    return "https://api.uat.magikmat.net/api/";
  }else  if (urlPath === "app.magikmat.com") {
    return "https://api.app.magikmat.com/api/";
  }else  if (urlPath === "localhost:3000") {
    return "http://localhost:5256/api/";
  }
  return "https://api.magikmat.net/api/";
};

export const getGamePlayUrl = () => {
  if (urlPath === "app.magikmat.com") {
    return "https://api.app.magikmat.com/";
  } else if (urlPath === "uat.magikmat.net") {
    return "https://api.uat.magikmat.net/";
  }
  return "https://api.magikmat.net/";
};

axiosInstance.defaults.baseURL = getEnvUrl();

export default axiosInstance;
