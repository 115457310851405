import axiosInstance from "../../../utils/Api";

export const CreateFavoriteGameApi = async (data) =>
  await axiosInstance
    .post("game/createFavGame", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const RemoveFavoriteGameApi = async (data) =>
  await axiosInstance
    .delete("game/removeFavGame", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GetFavoriteGamesApi = async (data) =>
  await axiosInstance
    .get("game/getFavouriteGames", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});
