import React, { useState } from 'react';
import { Link } from "react-router-dom";

export default function BannerGameImages({ img }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <>
            <div className={imageLoaded ? "" : "hide-visibility"}>
                <Link to={`/game-detail/${img.game_id}`}>
                    <div className="banner-actions-container">
                        <div className="banner-desc">{img.description}</div>
                        <button className="banner-play-button">Play Now</button>
                    </div>
                    <img
                        src={img.src}
                        alt={img.description}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => console.log("error loading")}
                    />
                </Link>
            </div>
            <div
                className={imageLoaded ? "hide-visibility" : "sample-banner"}
            ></div>
        </>
    )
}
