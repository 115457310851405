import {
  ONLINE_PAYMENT,
  ONLINE_PAYMENT_DATA,
  ONLINE_PAYMENT_ERROR,
  PAYMENT_RESPONSE,
  PAYMENT_RESPONSE_DATA,
  PAYMENT_RESPONSE_ERROR,
  PAYMENT_HISTORY,
  PAYMENT_HISTORY_DATA,
  PAYMENT_HISTORY_ERROR,
  PAYMENT_RECEIPT,
  PAYMENT_RECEIPT_DATA,
  PAYMENT_RECEIPT_ERROR,
} from "../constants";

export const OnlinePayment = (data) => {
  return {
    type: ONLINE_PAYMENT,
    data: data,
  };
};

export const OnlinePaymentData = (data) => {
  return {
    type: ONLINE_PAYMENT_DATA,
    data: data,
  };
};

export const OnlinePaymentError = (data) => {
  return {
    type: ONLINE_PAYMENT_ERROR,
    data: data,
  };
};

export const PaymentResponse = (data) => {
  return {
    type: PAYMENT_RESPONSE,
    data: data,
  };
};

export const PaymentResponseData = (data) => {
  return {
    type: PAYMENT_RESPONSE_DATA,
    data: data,
  };
};

export const PaymentResponseError = (data) => {
  return {
    type: PAYMENT_RESPONSE_ERROR,
    data: data,
  };
};

export const PaymentHistory = (data) => {
  return {
    type: PAYMENT_HISTORY,
    data: data,
  };
};

export const PaymentHistoryData = (data) => {
  return {
    type: PAYMENT_HISTORY_DATA,
    data: data,
  };
};

export const PaymentHistoryError = (data) => {
  return {
    type: PAYMENT_HISTORY_ERROR,
    data: data,
  };
};

export const PaymentReceipt = (data) => {
  return {
    type: PAYMENT_RECEIPT,
    data: data,
  };
};

export const PaymentReceiptData = (data) => {
  return {
    type: PAYMENT_RECEIPT_DATA,
    data: data,
  };
};

export const PaymentReceiptError = (data) => {
  return {
    type: PAYMENT_RECEIPT_ERROR,
    data: data,
  };
};
