import {
  CHECK_WISHLIST_DATA,
  CREATE_WATCHLIST_DATA,
  GET_WATCHLIST_GAMES_DATA,
  REMOVE_WATCHLIST_DATA,
  SET_DEFAULT_WATCHLIST_GAMES,
} from "../../constants";

const INIT_STATE = {
  createWatchlistData: {},
  removeWatchlistGameData: {},
  watchlistGamesData: [],
  watchlistGamesMsg: "",
  checkWishlistData: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_WATCHLIST_DATA: {
      return {
        ...state,
        createWatchlistData: action.data,
      };
    }
    case REMOVE_WATCHLIST_DATA: {
      return {
        ...state,
        removeWatchlistGameData: action.data,
      };
    }
    case GET_WATCHLIST_GAMES_DATA: {
      return {
        ...state,
        watchlistGamesData: [...state.watchlistGamesData, ...action.data.data],
        watchlistGamesMsg: action.data.message,
      };
    }
    case SET_DEFAULT_WATCHLIST_GAMES: {
      return {
        ...state,
        watchlistGamesData: [],
        watchlistGamesMsg: "",
      };
    }
    case CHECK_WISHLIST_DATA: {
      return {
        ...state,
        checkWishlistData: {...state.checkWishlistData, [action.data.id]: action.data.data}
      };
    }

    default:
      return state;
  }
};
