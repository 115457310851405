import {
  MY_SUBSCRIPTION_DATA,
  SUBSCRIPTION_PLAN_LIST_DATA,
  MY_UPGRADE_PLAN_DATA
} from "../constants";

const INIT_STATE = {
  subscriptionPlanListData: [],
  mySubscriptionData: {},
  subscriptionUpgradeData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_LIST_DATA: {
      return {
        ...state,
        subscriptionPlanListData: action.data,
      };
    }
    case MY_SUBSCRIPTION_DATA: {
      return {
        ...state,
        mySubscriptionData: action.data,
      };
    }
    case MY_UPGRADE_PLAN_DATA: {
      return {
        ...state,
        subscriptionUpgradeData: action.data,
      };
    } 
    default:
      return state;
  }
};
