import axiosInstance from "../../../utils/Api";
import { debounce } from 'lodash';

export const getGameGrades = async () =>
  await axiosInstance
    .get("admin/game/grade")
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});


export const GameDetailsApi = async (data) =>
  await axiosInstance
    .get("game/gamedetails", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GetCategoryGamesApi = async (data) =>
  await axiosInstance
    .get("game/getcategorygames", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GameBannersApi = async (data) =>
  await axiosInstance
    .get("game/gameBanners", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const HomePageGamesApi = async (data) =>
  await axiosInstance
    .get("game/homePage", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const GetRecommendedGamesApi = async (data) =>
  await axiosInstance
    .get("game/getRecommendGames", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const SearchGamesApi = async (data) =>
  await axiosInstance
    .get("game/search", {
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

// export const SearchGamesApi = debounce(async (data) => {
//   try {
//     const response = await axiosInstance.get("game/search", {
//       params: data,
//     });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// }, 300);

export const PlayGameApi = async (data) =>
  await axiosInstance
    .get("game/playGame", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: data,
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {});

export const AutoSuggessionApi = async (input) =>
    await axiosInstance
      .post("game/suggest", input)
      .then((data) => {
        return {data:data.data,input:input};
      })
      .catch((error) => {});

export const GetGradeGamesApi = async (data) =>
      await axiosInstance
        .get("game/getGradegames", {
          params: data,
        })
        .then((data) => {
          return data.data;
        })
        .catch((error) => {});
// const debouncedAutoSuggestionApi = debounce(async (input) => {
//   try {
//     const response = await axiosInstance.post("game/suggest", input);
//        return {data:response.data,input:input};;
//   } catch (error) {
//     console.error(error);
//   }
// }, 500);
// export const AutoSuggessionApi = async (data) => debouncedAutoSuggestionApi(data);

