import { combineReducers } from "redux";
import GameReducer from "./GameReducer";
import GameFavouriteReducer from "./GameFavouriteReducer";
import GameReviewReducer from "./GameReviewReducer";
import GameWatchlistReducer from "./GameWatchlistReducer";

const gameReducers = combineReducers({
  gameData : GameReducer,
  gameFavouriteData : GameFavouriteReducer,
  gameReviewData: GameReviewReducer,
  gameWatchlistData: GameWatchlistReducer
});

export default gameReducers;
