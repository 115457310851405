import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import profilepic from "../../assets/icons/profilepic.png";
import edit from "../../assets/icons/editprofile.png";
import fav from "../../assets/icons/favourites.png";
import playlist from "../../assets/icons/playlist.png";
import setting from "../../assets/icons/settings.png";
import reffer from "../../assets/icons/reffer.png";
import logout from "../../assets/icons/logout.png";
import "./Navbar.css";

function UserNav({ showUserDropdown, setShowUserDropdown, handleLogoutUser }) {
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowUserDropdown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef}>
      <img
        src={profilepic}
        alt="user-profile"
        className="nav-user-img"
        onClick={() => setShowUserDropdown(!showUserDropdown)}
      />
      {showUserDropdown && (
        <div className="nav-user-drop-down">
          <Link
            to="/profile"
            className="nav-login-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <img src={edit} alt="edit profile" className="editimag" />
            Edit Profile
          </Link>
          <Link
            to="/games/favourites"
            className="nav-login-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <img src={fav} alt="Favourites" className="editimag" />
            Favourites
          </Link>
          <Link
            to="/games/playlist"
            className="nav-login-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <img src={playlist} alt="playlist" className="editimag" />
            Playlist
          </Link>
          {/* For next versions */}
          {/* <Link
            to="/"
            className="nav-login-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <img src={setting} alt="settings" className="editimag" />
            Settings
          </Link>
          <Link
            to="/"
            className="nav-login-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <img src={reffer} alt="reffer and earn" className="editimag" />
            Reffer & earn
          </Link> */}
          <button
            to="/"
            className="nav-login-dropdown nav-logout-btn"
            onClick={() => handleLogoutUser()}
          >
            <img src={logout} alt="logout" className="editimag" />
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default UserNav;
