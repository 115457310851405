import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { encodedId } from "../../../utils/Common";
import {
  CheckWishlist,
  CreateFavoriteGameData,
  FetchError,
  FetchStart,
  FetchSuccess,
  GetFavoriteGamesData,
  RemoveFavoriteGameData,
  ShowToastMessage,
} from "../../actions";
import {
  CreateFavoriteGameApi,
  GetFavoriteGamesApi,
  RemoveFavoriteGameApi,
} from "../../api";
import {
  CREATE_FAVORITE_GAME,
  GET_FAVORITE_GAMES,
  REMOVE_FAVORITE_GAME,
} from "../../constants";

function* CreateFavoriteGameService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(CreateFavoriteGameApi, data);
    if (res) {
      yield put(CreateFavoriteGameData(res.data));
      yield put(CheckWishlist({ game_id: encodedId(data.game_id), id: data.game_id }));
      yield put(ShowToastMessage('Game successfully added to Favorite'));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* RemoveFavoriteGameService({ data }) {
  try {
    // yield put(FetchStart());
    const res = yield call(RemoveFavoriteGameApi, data);
    if (res) {
      yield put(RemoveFavoriteGameData(res.data));
      yield put(CheckWishlist({ game_id: encodedId(data.game_id), id: data.game_id }));
      yield put(ShowToastMessage('Game successfully removed from Favorite'));
      
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* GetFavoriteGamesService({ data }) {
  try {
    yield put(FetchStart());
    const res = yield call(GetFavoriteGamesApi, data);
    if (res) {
      yield put(GetFavoriteGamesData(res));
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {
    yield put(FetchError());
  }
}

export function* CreateFavoriteGameSaga() {
  yield takeEvery(CREATE_FAVORITE_GAME, CreateFavoriteGameService);
}

export function* RemoveFavoriteGameSaga() {
  yield takeEvery(REMOVE_FAVORITE_GAME, RemoveFavoriteGameService);
}

export function* GetFavoriteGamesSaga() {
  yield takeEvery(GET_FAVORITE_GAMES, GetFavoriteGamesService);
}

export default function* rootSaga() {
  yield all([
    fork(CreateFavoriteGameSaga),
    fork(RemoveFavoriteGameSaga),
    fork(GetFavoriteGamesSaga),
  ]);
}
