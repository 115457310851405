import {
  ONLINE_PAYMENT_DATA,
  PAYMENT_HISTORY_DATA,
  PAYMENT_RECEIPT_DATA,
  PAYMENT_RESPONSE_DATA,
} from "../constants";

const INIT_STATE = {
  onlinePaymentData: null,
  paymentResponseData: {},
  paymentHistoryData: {},
  paymentReceiptData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ONLINE_PAYMENT_DATA: {
      return {
        ...state,
        onlinePaymentData: action.data,
      };
    }
    case PAYMENT_RESPONSE_DATA: {
      return {
        ...state,
        paymentResponseData: action.data,
      };
    }
    case PAYMENT_HISTORY_DATA: {
      return {
        ...state,
        paymentHistoryData: action.data,
      };
    }
    case PAYMENT_RECEIPT_DATA: {
      return {
        ...state,
        paymentReceiptData: action.data,
      };
    }

    default:
      return state;
  }
};
