import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { MySubscriptionData, SubscriptionPlanListData,SubscriptionUpgadePlanData } from "../actions";
import { MySubscriptionApi, SubscriptionPlanListApi,MySubscriptionUpgradeApi } from "../api";
import { MY_SUBSCRIPTION, SUBSCRIPTION_PLAN_LIST,MY_UPGRADE_PLAN } from "../constants";
 
function* SubscriptionPlanListService() {
  try {
    // yield put(FetchStart());
    const res = yield call(SubscriptionPlanListApi);
    if (res) {
      console.log(res.data);
      yield put(SubscriptionPlanListData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* MySubscriptionService() {
  try { 
    // yield put(FetchStart());
    const res = yield call(MySubscriptionApi);
    if (res) {
      yield put(MySubscriptionData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}
function* SubscriptionUpgradePlanService() {
  try {
    // yield put(FetchStart()); 
    const res = yield call(MySubscriptionUpgradeApi);
    if (res) {
      yield put(SubscriptionUpgadePlanData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
} 
export function* SubscriptionPlanSaga() {
  yield takeEvery(SUBSCRIPTION_PLAN_LIST, SubscriptionPlanListService);
}

export function* MySubscriptionSaga() {
  yield takeEvery(MY_SUBSCRIPTION, MySubscriptionService);
}

export function* MySubscriptionUpgradeSaga() {
  yield takeEvery(MY_UPGRADE_PLAN, SubscriptionUpgradePlanService);
}
SubscriptionUpgradePlanService();

export default function* rootSaga() {
  yield all([fork(SubscriptionPlanSaga), fork(MySubscriptionSaga), fork(SubscriptionUpgradePlanService)]);
}
