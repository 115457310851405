import React, { useRef, useEffect } from "react";
import { urlPath } from "../../utils/Api";
import "./Navbar.css";

function NavMenuHover({
  subjectData,
  handleSelectedSubMenu,
  handleShowSubMenu,
  selectedSubMenuHeader,
  handleSelectedMenu,
}) {
  const subjectMenuRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        subjectMenuRef.current &&
        !subjectMenuRef.current.contains(event.target) &&
        !event.target.classList.contains("nav-menu-container")
      ) {
        handleShowSubMenu(false);
        !selectedSubMenuHeader.id && handleSelectedMenu("");
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [subjectMenuRef]);
  // const handleSubjectNavigate = (sub) => {
  //   navigate(`/games/subject-${sub.id}`);
  // }
  let subjectIconUrl = urlPath;
  if(urlPath !== "uat.magikmat.net") {
    subjectIconUrl = "magikmat.net";
  }
  
  return (
    <div ref={subjectMenuRef} className="nav-main-drop-down">
      {subjectData?.map((option, index) => (
        <div
          key={option.name}
          onClick={() => {
            handleSelectedSubMenu(option);
            handleShowSubMenu(false);
            // handleSubjectNavigate(option);
          }}
          className={
            selectedSubMenuHeader.name === option.name
              ? "nav-main-drop-down-item selected"
              : "nav-main-drop-down-item"
          }
        >
          <img
            key={index}
            src={`https://api.${subjectIconUrl}/api/game/getlevelimage?id=${option.id}`}
            alt={`Image ${index}`}
          />
          <span className="nav-main-drop-down-label">
            {option.name}
            {/* {option?.gamelevelthumbnails[0]?.thumbnails} */}
          </span>
        </div>
      ))}
    </div>
  );
}
export default NavMenuHover;
