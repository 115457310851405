import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import './ErrorComponent.css'

function ErrorComponent() {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
        window.location.replace('/');
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div className='error-component'>
      <h2>Oops!! This page is not avaliable.</h2>
      <h3>Redirecting to Home page with in {seconds} Seconds...</h3>
      <Link to={`/`} className='home-button'>Return to Home page</Link>
    </div>
  )
};

export default ErrorComponent;