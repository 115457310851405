export const GAME_DETAILS = "GAME_DETAILS";
export const GAME_DETAILS_DATA = "GAME_DETAILS_DATA";
export const GAME_DETAILS_ERROR = "GAME_DETAILS_ERROR";
export const GET_CATEGORY_GAMES = "GET_CATEGORY_GAMES";
export const GET_CATEGORY_GAMES_DATA = "GET_CATEGORY_GAMES_DATA";
export const GET_CATEGORY_GAMES_ERROR = "GET_CATEGORY_GAMES_ERROR";
export const SET_DEFAULT_CATEGORY_GAMES = "SET_DEFAULT_CATEGORY_GAMES";
export const GAME_BANNERS = "GAME_BANNERS";
export const GAME_BANNERS_DATA = "GAME_BANNERS_DATA";
export const GAME_BANNERS_ERROR = "GAME_BANNERS_ERROR";
export const SET_DEFAULT_BANNERS_GAMES = "SET_DEFAULT_BANNERS_GAMES";
export const CREATE_FAVORITE_GAME = "CREATE_FAVORITE_GAME";
export const CREATE_FAVORITE_GAME_DATA = "CREATE_FAVORITE_GAME_DATA";
export const CREATE_FAVORITE_GAME_ERROR = "CREATE_FAVORITE_GAME_ERROR";
export const REMOVE_FAVORITE_GAME = "REMOVE_FAVORITE_GAME";
export const REMOVE_FAVORITE_GAME_DATA = "REMOVE_FAVORITE_GAME_DATA";
export const REMOVE_FAVORITE_GAME_ERROR = "REMOVE_FAVORITE_GAME_ERROR";
export const GET_FAVORITE_GAMES = "GET_FAVORITE_GAMES";
export const GET_FAVORITE_GAMES_DATA = "GET_FAVORITE_GAMES_DATA";
export const GET_FAVORITE_GAMES_ERROR = "GET_FAVORITE_GAMES_ERROR";
export const SET_DEFAULT_FAVORITE_GAMES = "SET_DEFAULT_FAVORITE_GAMES";
export const CREATE_WATCHLIST = "CREATE_WATCHLIST";
export const CREATE_WATCHLIST_DATA = "CREATE_WATCHLIST_DATA";
export const CREATE_WATCHLIST_ERROR = "CREATE_WATCHLIST_ERROR";
export const REMOVE_WATCHLIST = "REMOVE_WATCHLIST";
export const REMOVE_WATCHLIST_DATA = "REMOVE_WATCHLIST_DATA";
export const REMOVE_WATCHLIST_ERROR = "REMOVE_WATCHLIST_ERROR";
export const GET_WATCHLIST_GAMES = "GET_WATCHLIST_GAMES";
export const GET_WATCHLIST_GAMES_DATA = "GET_WATCHLIST_GAMES_DATA";
export const GET_WATCHLIST_GAMES_ERROR = "GET_WATCHLIST_GAMES_ERROR";
export const SET_DEFAULT_WATCHLIST_GAMES = "SET_DEFAULT_WATCHLIST_GAMES";
export const ADD_REVIEW = "ADD_REVIEW";
export const ADD_REVIEW_DATA = "ADD_REVIEW_DATA";
export const ADD_REVIEW_ERROR = "ADD_REVIEW_ERROR";
export const REMOVE_REVIEW = "REMOVE_REVIEW";
export const REMOVE_REVIEW_DATA = "REMOVE_REVIEW_DATA";
export const REMOVE_REVIEW_ERROR = "REMOVE_REVIEW_ERROR";
export const GET_REVIEW = "GET_REVIEW";
export const GET_REVIEW_DATA = "GET_REVIEW_DATA";
export const GET_REVIEW_ERROR = "GET_REVIEW_ERROR";
export const HOME_PAGE_GAMES = "HOME_PAGE_GAMES";
export const HOME_PAGE_GAMES_DATA = "HOME_PAGE_GAMES_DATA";
export const HOME_PAGE_GAMES_ERROR = "HOME_PAGE_GAMES_ERROR";
export const SET_DEFAULT_HOME_PAGE_GAMES = "SET_DEFAULT_HOME_PAGE_GAMES";
export const CHECK_WISHLIST = "CHECK_WISHLIST";
export const CHECK_WISHLIST_DATA = "CHECK_WISHLIST_DATA";
export const CHECK_WISHLIST_ERROR = "CHECK_WISHLIST_ERROR";
export const GET_RECOMMENDED_GAMES = "GET_RECOMMENDED_GAMES";
export const GET_RECOMMENDED_GAMES_DATA = "GET_RECOMMENDED_GAMES_DATA";
export const GET_RECOMMENDED_GAMES_ERROR = "GET_RECOMMENDED_GAMES_ERROR";
export const SET_DEFAULT_GAME_REVIEWS = "SET_DEFAULT_GAME_REVIEWS";
export const SEARCH_GAMES = "SEARCH_GAMES";
export const SEARCH_GAMES_DATA = "SEARCH_GAMES_DATA";
export const SEARCH_GAMES_ERROR = "SEARCH_GAMES_ERROR";
export const PLAY_GAME = "PLAY_GAME";
export const PLAY_GAME_DATA = "PLAY_GAME_DATA";
export const PLAY_GAME_ERROR = "PLAY_GAME_ERROR";
export const SET_DEFAULT_PLAY_GAME_DATA = "SET_DEFAULT_PLAY_GAME_DATA";
export const GET_GRADE_GAMES = "GET_GRADE_GAMES";
