import React from "react";
import "./ImageLoader.css";

export default function ImageLoader({
  src,
  alt = "Image Loader",
  height = "320px",
  width = "100%",
  className = "",
}) {
  if (src) {
    return <img src={src} height={height} width={width} alt={alt} />;
  }
  return (
    <div
      className={`${className} image-loader-background`}
      style={{ height: height, width: width }}
    ></div>
  );
}
