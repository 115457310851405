import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FetchStart,
  FetchSuccess,
  UserLoginData,
  UserProfileData,
  UserTokenCheckData,
  UserProfile,
  MySubscription,
  EditUserData,
  ShowToastMessage,
  UserAddressData,
  EditUserAddressData,
  UserAddress,
  StatesListData,
  PincodeDataData,
  PincodeSuccessData,
  StatesList
} from "../actions";
import {
  UserLoginApi,
  UserTokenCheckApi,
  UserProfileApi,
  EditUserApi,
  UserAddressApi,
  EditUserAddressApi,
  StatesListApi,
  PincodeDataApi,
} from "../api";
import {
  EDIT_USER,
  EDIT_USER_ADDRESS,
  PINCODE_DATA,
  STATES_LIST,
  USER_ADDRESS,
  USER_LOGIN,
  USER_PROFILE,
  USER_TOKEN_CHECK,
} from "../constants";

function* UsersLoginService({ data }) {
  try {
    yield put(FetchStart());
    const userLoginRes = yield call(UserLoginApi, data);
    if (userLoginRes.message === "user loggedin") {
      const tokenData = `Bearer ${userLoginRes.data[0].token}`;
      const userid = userLoginRes.data[0].userid;
      localStorage.setItem("token", tokenData);
      localStorage.setItem("userid", userid);
      yield put(UserLoginData(userLoginRes));
      yield put(UserProfile());
      yield put(MySubscription());
    } else {
    }
    yield put(FetchSuccess());
  } catch (error) {}
}

function* UsersTokenCheckService() {
  try {
    // yield put(FetchStart());
    const res = yield call(UserTokenCheckApi);
    if (res.message === "Authenticate successfull") {
      yield put(UserTokenCheckData(res));
      yield put(UserProfile());
      yield put(MySubscription());
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* UsersProfileService() {
  try {
    // yield put(FetchStart());
    const res = yield call(UserProfileApi);
    if (res) {
      yield put(UserProfileData(res.data));
      yield put(StatesList());
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* EditUserService(data) {
  try {
    // yield put(FetchStart());
    const res = yield call(EditUserApi, data.data);
    if (res.status) {
      yield put(ShowToastMessage("User Profile updated successfully."));
      yield put(EditUserData(res.data));
      yield put(UserProfile());
    } else {
      yield put(ShowToastMessage(res.errorMessage));
    }
    // yield put(FetchSuccess());
  } catch (error) {
    yield put(ShowToastMessage("Something went wrong. Please try again."));
  }
}

function* UsersAddressService() {
  try {
    // yield put(FetchStart());
    const res = yield call(UserAddressApi);
    if (res) {
      yield put(UserAddressData(res.data[0]));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* EditUserAddressService(data) {
  try {
    // yield put(FetchStart());
    const res = yield call(EditUserAddressApi, {...data.data});
    if (res) {
      yield put(ShowToastMessage("User Address updated successfully."));
      yield put(EditUserAddressData(res.data));
      yield put(UserAddress());
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {
    yield put(ShowToastMessage("Something went wrong. Please try again."));
  }
}

function* StatesListService() {
  try {
    // yield put(FetchStart());
    const res = yield call(StatesListApi);
    if (res) {
      yield put(StatesListData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

function* PincodeDataService({data}) {
  try {
    // yield put(FetchStart());
    const res = yield call(PincodeDataApi, data);
    if (res) {
      yield put(PincodeSuccessData(res.data));
    } else {
    }
    // yield put(FetchSuccess());
  } catch (error) {}
}

export function* UserLoginSaga() {
  yield takeEvery(USER_LOGIN, UsersLoginService);
}

export function* UserTokenCheckSaga() {
  yield takeEvery(USER_TOKEN_CHECK, UsersTokenCheckService);
}

export function* UserProfileSaga() {
  yield takeEvery(USER_PROFILE, UsersProfileService);
}

export function* EditUserSaga() {
  yield takeEvery(EDIT_USER, EditUserService);
}

export function* UserAddressSaga() {
  yield takeEvery(USER_ADDRESS, UsersAddressService);
}

export function* EditUserAddressSaga() {
  yield takeEvery(EDIT_USER_ADDRESS, EditUserAddressService);
}

export function* StatesListSaga() {
  yield takeEvery(STATES_LIST, StatesListService);
}

export function* PincodeDataSaga() {
  yield takeEvery(PINCODE_DATA, PincodeDataService);
}

export default function* rootSaga() {
  yield all([
    fork(UserLoginSaga),
    fork(UserTokenCheckSaga),
    fork(UserProfileSaga),
    fork(EditUserSaga),
    fork(UserAddressSaga),
    fork(EditUserAddressSaga),
    fork(StatesListSaga),
    fork(PincodeDataSaga),
  ]);
}
