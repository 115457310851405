// SearchAutocomplete.js
import React, { useState } from 'react';
import './SearchAutocomplete.css';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import search from "../../assets/icons/search.png";
import searchwhite from "../../assets/icons/searchwhite.png";
import {
  AutoSuggessionApi
} from "../../redux/api";
import { getEnvUrl, getGamePlayUrl } from "../../utils/Api";
const SearchAutocomplete = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const options = useSelector(
    (state) => state.gameStore.gameAutoSuggestData
  );
  function keyUpHandler(refName, e) {
    if (refName.key == 'Enter') {
      navigate(`/games/search-${query}`);
      setSuggestions([]);
    }
  }
  // Function to handle input change
  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    if (!inputValue) {
      setSuggestions([]);
      return;
    }
  let rlt = await AutoSuggessionApi({term:event.target.value});
     await setSuggestions(rlt.data.data);   
    console.log('suggestions : ',inputValue,suggestions,rlt.input);
    return;  
  };
  const getimagepath = (id) => {
    return `${getEnvUrl()}game/img?type=h&id=${btoa(btoa(id))}&width=60`
  }
  // Function to handle suggestion click
  const handleSuggestionClick = (id) => {
    setQuery('');
    setSuggestions([]);
    navigate(`/game-detail/${id}`);
  };

  return (
    <div className={  query ? "search-container active" : "search-container"  } >
                
                <div className="search-icon">
                  <img src={search} alt="search data" />
                </div>
               
                <div className="search-input-container"> 
    <div className="search-autocomplete">
      <input
        type="text"
        className="search-input button-view asi"
        placeholder="Search data"
        value={query}
        onChange={handleInputChange}
        onKeyUp={keyUpHandler}
        cla='asi'
      />

      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={suggestion.gameid+1} onClick={() => handleSuggestionClick(suggestion.gameid)}>
              {/* {suggestion} Experience */}
              <div key={suggestion.gameid+2}  class="paragraphs">
                <div class="row" key={suggestion.gameid+3} >
                        <div class="span4" key={suggestion.gameid+4}>
                        <img key={suggestion.gameid+5} class="simage"   src={getimagepath(suggestion.gameid)}/>
                        <div key={suggestion.gameid+6} class="content-heading"><strong key={suggestion.gameid+7} > {suggestion.gamename}  </strong></div>
                        <p class='clearboth'></p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <img src={searchwhite} className="search-input-icon" />
      </div>
    </div>
  );
};

export default SearchAutocomplete;
