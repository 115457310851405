import React from "react";
import Carousel from "react-multi-carousel";
import ImageLoader from "../imageLoader/ImageLoader";
import GradeView from "../gameVIew/GradeView";
 
import "./GameListCorousal.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1023, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
const basicImgData = {
  width: "152px",
  height: "calc(100vw * 0.14)",
};

export default function GradeCorousal({
  title,
  imagesData,
  handleImageLoad,
  viewtype
}) {
  return (
    <div className="corousal-view-container">
      {imagesData.length ? (
        <>
          <div className="corousal-name">{title} </div> 
          <Carousel
            responsive={responsive}
            containerClass="corousal-container gametemplate"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            slidesToSlide={6}
          >
            {imagesData?.map((image, idx) => (            
              <div key={image.grade_id+idx}>               
                 <GradeView image={image} handleImageLoad={handleImageLoad}/>                 
              </div>
            ))}
          </Carousel>  
        </>
      ) :
      
      
      (
        <div className="corousal-loader-view">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((ele, idx) => (
            <div key={idx}>
              <ImageLoader {...basicImgData} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
