import { all } from 'redux-saga/effects';
import userSagas from './UserSagas';
import paymentSagas from './PaymentSagas';
import subscriptionSagas from './SubscriptionSagas';
import subjectSagas from './SubjectSagas';
import GameRootSaga from './Game';

export default function* rootSaga() {
   yield all([
      userSagas(),
      paymentSagas(),
      subscriptionSagas(),
      subjectSagas(),
      GameRootSaga(),
   ]);
};