export const ONLINE_PAYMENT = "ONLINE_PAYMENT";
export const ONLINE_PAYMENT_DATA = "ONLINE_PAYMENT_DATA";
export const ONLINE_PAYMENT_ERROR = "ONLINE_PAYMENT_ERROR";
export const PAYMENT_RESPONSE = "PAYMENT_RESPONSE";
export const PAYMENT_RESPONSE_DATA = "PAYMENT_RESPONSE_DATA";
export const PAYMENT_RESPONSE_ERROR = "PAYMENT_RESPONSE_ERROR";
export const PAYMENT_HISTORY = "PAYMENT_HISTORY";
export const PAYMENT_HISTORY_DATA = "PAYMENT_HISTORY_DATA";
export const PAYMENT_HISTORY_ERROR = "PAYMENT_HISTORY_ERROR";
export const PAYMENT_RECEIPT = "PAYMENT_RECEIPT";
export const PAYMENT_RECEIPT_DATA = "PAYMENT_RECEIPT_DATA";
export const PAYMENT_RECEIPT_ERROR = "PAYMENT_RECEIPT_ERROR";
