import {combineReducers} from "redux";
import User from "./UserReducer";
import Payment from "./PaymentReducer";
import Subscription from "./SubscriptionReducer";
import Game from "./Game";
import Subject from "./SubjectReducer";
import AppCommon from "./AppCommonReducer";

const reducers = combineReducers({
	userStore : User,
	paymentStore : Payment,
	subscriptionStore : Subscription,
	gameStore : Game,
	subjectStore : Subject,
	appCommon : AppCommon,
});

export default reducers;
